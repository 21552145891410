import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  public info = `error-dialog.info`;
  public error: Error;
  public serverError: string;
  public isProduction: boolean;
  public noGroup: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Error | HttpErrorResponse, private dialogRef: MatDialogRef<ErrorDialogComponent>
  ) { }

  ngOnInit() {
    console.error(this.data);
    this.setError(this.data);
    this.isProduction = environment.production;
  }

  /**
   * Sets error additional error dialog description, used to display error detail in dialog.
   *
   * @param data
   * @memberof ErrorDialogComponent
   */
  setError(data: Error | HttpErrorResponse): void {
    if (data instanceof HttpErrorResponse) {
      this.error = typeof data.error === 'string' ? new Error(data.error) : data.error;
      this.info = data.status === 400 ? `error-dialog.validation-info` : this.error.message;
      this.serverError = `Status: ${data.status}, Message: ${data.statusText}`;
      if (data.status === 403) {
        this.dialogRef.disableClose = true;
        this.noGroup = true;
      }
      else {
        this.dialogRef.disableClose = false;
        this.noGroup = false;
      }
    } else {
      this.error = data;
    }
  }
}
