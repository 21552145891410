import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsendResponse } from '@sirius/common';
import { UrlService } from '../../core/services/url.service';
import { CommunicationData } from '../interfaces/communication-data.interface';
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlService: UrlService,
  ) { }

  /**
   * Returns list of notifications and maps them to DAO objects
   *
   * @returns
   * @memberof NotificationsService
   */
  getNotifications(): Observable<Notification[]> {
    return this.httpClient.get(this.urlService.getUrl('COMMUNICATIONS')).pipe(
      map((response: JsendResponse<CommunicationData[]>) => response.data.map((item) => new Notification('COMMUNICATION', item)))
    );
  }
}
