import { ISerializeable } from '../core/interfaces/serializeable.interface';
import { IModel } from '../core/models/mode.interface';
import { Job } from '../jobs/models/job.model';

export type SearchResultType<T> = T;

export class SearchResult<T extends ISerializeable<any> = any> {
  type: string;
  data: ReturnType<T['serialize']>;

  constructor(data: T) {
    this.type = Object.getPrototypeOf(data).constructor.name;
    this.data = data.serialize();
  }
};
