import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RecentComponent } from './components/recent/recent.component';
import { Store, StoreModule } from '@ngrx/store';
import { RECENT_STATE_KEY, recentReducer } from './store/recent.reducer';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  declarations: [RecentComponent],
  imports: [
    SharedModule,
    CommonModule,
    StoreModule.forFeature(RECENT_STATE_KEY, recentReducer),
    AppRoutingModule
  ],
  exports: [
    RecentComponent
  ]
})
export class RecentModule { }
