import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchComponent } from './global-search.component';
import { SharedModule } from '../shared/shared.module';
import { RecentModule } from '../recent/recent.module';
import { StoreModule } from '@ngrx/store';
import { globalSearchReducer, GLOBAL_SEARCH_STATE_KEY } from './store/global-search.reducer';
import { GlobalSearchEffects } from './store/global-search.effects';
import { EffectsModule } from '@ngrx/effects';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchResultsComponent } from './search-results/search-results.component';

@NgModule({
  declarations: [GlobalSearchComponent, SearchResultsComponent],
  exports: [GlobalSearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    RecentModule,
    ScrollingModule,
    StoreModule.forFeature(GLOBAL_SEARCH_STATE_KEY, globalSearchReducer),
    EffectsModule.forFeature([GlobalSearchEffects])
  ]
})
export class GlobalSearchModule { }
