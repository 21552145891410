import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, HostBinding, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { AppState } from '../core/interfaces/state.interface';
import { Job } from '../jobs/models/job.model';
import { SearchResult } from './search-result';
import { loadMoreResults, triggerSearch } from './store/global-search.actions';
import { selectGlobalSearchLoading, selectGlobalSearchSearchResults } from './store/global-search.selectors';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @HostBinding('class.global-search') globalSearch = true;
  @HostBinding('class.global-search--focus') globalSearchFocus = false;
  @ViewChild('globalSearchResultsTrigger') globalSearchResultsTrigger: MatMenuTrigger;
  searchText = new UntypedFormControl('');
  searchResults: SearchResult<Job>[];
  loading: boolean;
  // destroyed$ = new Subject<boolean>();
  subs$ = new Subscription();
  public readonly listItemHeight = 111;
  private readonly _debounceTime = 300;

  constructor(
    private readonly store: Store,
  ) { }

  ngOnInit(): void {

    this.subs$.add(
      this.store.select(selectGlobalSearchSearchResults)
      .pipe(
        tap(searchResults => this.searchResults = searchResults)
      )
      .subscribe(
        {error: (error) => console.log(error)}
      ));

    this.subs$.add(
      this.store.select(selectGlobalSearchLoading)
      .pipe(
        tap(loading => this.loading = loading)
      )
      .subscribe(
        {error: (error) => console.log(error)}
      ));


    this.searchText.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this._debounceTime),
    ).subscribe(searchText => this.store.dispatch(triggerSearch({ searchText })));

  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  fetchMore(): void {
    const start = this.searchResults.length;
    const end = start + 10;
    this.store.dispatch(loadMoreResults({ start, end }));
  }

  openMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.globalSearchResultsTrigger.openMenu();
    this.globalSearchFocus = true;
  }

}
