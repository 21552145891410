import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, type: string, markAsSafe: boolean): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {

    switch (type) {
      case 'html': return markAsSafe ? this.sanitizer.bypassSecurityTrustHtml(value) :
        this.sanitizer.sanitize(SecurityContext.HTML, value);
      case 'style': return markAsSafe ? this.sanitizer.bypassSecurityTrustStyle(value) :
        this.sanitizer.sanitize(SecurityContext.STYLE, value);
      case 'script': return markAsSafe ? this.sanitizer.bypassSecurityTrustScript(value) :
        this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
      case 'url': return markAsSafe ? this.sanitizer.bypassSecurityTrustUrl(value) :
        this.sanitizer.sanitize(SecurityContext.URL, value);
      case 'resourceUrl': return markAsSafe ? this.sanitizer.bypassSecurityTrustResourceUrl(value) :
        this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }

  }
}
