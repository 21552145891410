import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Input() reg: string;
  @Input() small: boolean;
  @Input() alt: boolean;

  constructor() { }

  ngOnInit() {}

}
