import { ISerializeable } from '../interfaces/serializeable.interface';
import { Helper } from '../utils/helper';

/**
 * This is abstract frontend model, any mongodb based model should extend this one
 *
 * @export
 * @abstract
 * @class Model
 * @template T
 */
export abstract class Model<T> implements ISerializeable<T> {
    /** Mongoose id  */
    _id: string;
    /** This field can be used to show loading spinners for single element etc. */
    isLoading: boolean;
    /** This field can be used to show state of model */
    isEditable: boolean;

    constructor(config: T) {
        Object.assign(this, config);
    }

    serialize(): T {
      return Helper.serialize(this as unknown as T);
    }

}
