import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';
import { AboutDialogComponent } from '../about-dialog/about-dialog.component';
import { selectUserProfile } from '../../store/core.selectors';
import { tap } from 'rxjs';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  public userProfile: Keycloak.KeycloakProfile;
  public userRoles: string[] = [];
  public userInitials = '';

  constructor(
    private keycloakService: KeycloakService,
    protected readonly dialog: MatDialog,
    private readonly store: Store
    ) { }

  get currentUser(): string {
    return `${this.userProfile.firstName} ${this.userProfile.lastName || ''}`;
  }

  ngOnInit() {
    this._loadUserProfile();
    this.userRoles = this.keycloakService.getUserRoles(true);
  }

  /**
   * Returns current user full name.
   */

  /**
   * Logout current user
   *
   * @memberof UserMenuComponent
   */
  logout(): void {
    this.keycloakService.logout();
  }

  about(): void {
    const dialogRef = this.dialog.open(AboutDialogComponent, {
      height: '600px',
      width: '600px',
    });
  }

  isAdmin(): boolean {
    return this.userRoles.indexOf('Bodyshop Admin') > -1;
  }

  private async _loadUserProfile() {
    this.store.select(selectUserProfile).pipe(
      tap(userProfile => this.userProfile = userProfile)
      ).subscribe( {
        error: (error) => console.log(error),
    });
  }
}
