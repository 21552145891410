<div class="search-results">
    <h2 class="search-results__header">{{ 'search-results.title' | translate}}</h2>
    <p *ngIf="!searchResults.length && !loading" class="search-results__no-history">{{'search-results.no-history' | translate}}</p>
    <cdk-virtual-scroll-viewport #scroller [itemSize]="listItemHeight" class="search-results__viewport">
        <ul class="search-results__list">
            <ng-container *cdkVirtualFor="let searchResult of searchResults">
                <li class="search-results__item">
                    <a [routerLink]="['/jobs/', searchResult.data._id,'details']">
                        <app-job-card [job]="searchResult.data"></app-job-card>
                    </a>
                </li>
            </ng-container>
            <div class="search-results__loading" *ngIf="loading">
                <fa-icon [icon]="['fas', 'spinner']" class="fa-spin fas"></fa-icon>
            </div>
        </ul>
    </cdk-virtual-scroll-viewport>
</div>
