import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Component, ComponentRef } from '@angular/core';
import { Observable, throwError, timer, of } from 'rxjs';
import { finalize, mergeMap, retryWhen, timeout, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BannerService } from '../../banner/banner.service';
import { BadConnectionBannerComponent } from '../components/bad-connection-banner/bad-connection-banner.component';
import { BannerRef } from '../../banner/banner-ref';

@Injectable()
export class TimeoutRetryInterceptor implements HttpInterceptor {
  private badConnectionBannerRef: BannerRef<BadConnectionBannerComponent>;

  constructor(private readonly bannerService: BannerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      timeout(environment.requestTimeout),
      retryWhen((attempts: Observable<any>) => attempts.pipe(
          mergeMap((error, i) => {
            const retryAttempt = i + 1;
            if (retryAttempt > environment.requestRetry || error.name !== 'TimeoutError') {
              return throwError(error);
            }

            try {
              if (!this.badConnectionBannerRef) {
                this.badConnectionBannerRef = this.bannerService.open(BadConnectionBannerComponent);
              }
            } catch (e) {
              console.error(e);
            }

            console.log(`Attempt ${retryAttempt}: retrying...`);

            return of(retryAttempt);
          }),
          finalize(() => {
            if (this.badConnectionBannerRef) {
              this.badConnectionBannerRef.close();
              this.badConnectionBannerRef = undefined;
            }
          })
        )),
    );
  }
}
