import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import { Helper } from '@sirius/common';
import { environment } from '../../../environments/environment';

/**
 * Handles retry for server errors (500+)
 *
 * @export
 * @class ServerErrorHttpInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ServerErrorRetryInterceptor implements HttpInterceptor {
  private readonly includeErrorCodes: number[] = [
    502,
    503,
    504,
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen((attempts: Observable<any>) => attempts.pipe(
          mergeMap((error, i) => {
            const retryAttempt = i + 1;
            const retryDelay = Helper.fibonacciIter(retryAttempt) * 1000;
            if (
              retryAttempt > environment.requestRetry ||
              !this.includeErrorCodes.includes(error.status)
            ) {
              return throwError(error);
            }

            console.log(`${error.message} - Retry attempt ${retryAttempt} in ${retryDelay}...`);

            return timer(retryDelay);
          }),
        )),
    );
  }
}
