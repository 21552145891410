import { HttpClient } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { JsendResponse } from '@sirius/common';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { map } from 'rxjs/operators';
import { DEFAULT_CURRENCY_DISPLAY } from '../core.constants';
import { IJobStats } from '../interfaces/job-stats.interface';
import { UrlService } from './url.service';

export  interface CurrencyTokenExtension {
  locale: string;
  currency: string;
  currencyDisplay: string;
}

/**
 * Service is providing all core data that should be used in core module (such as job statistics)
 *
 * @export
 * @class CoreService
 */
@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlService: UrlService,
    private readonly keycloakService: KeycloakService,
    @Inject(LOCALE_ID) private readonly defaultLocale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private readonly defaultCurrency: string,
    @Inject(DEFAULT_CURRENCY_DISPLAY) private readonly defaultCurrencyDisplay: string,
  ) { }

  private get token(): KeycloakTokenParsed & CurrencyTokenExtension {
    return this.keycloakService.getKeycloakInstance().idTokenParsed as KeycloakTokenParsed & CurrencyTokenExtension;
  }

  /**
   * Return's job statistic from server
   *
   * @memberof CoreService
   */
  getJobStats() {
    return this.httpClient.get(this.urlService.getUrl('JOBS_STATS')).pipe(
      map((response: JsendResponse<IJobStats>) => response.data),
    );
  }

  getUserLocale(): string {
    return this.token.locale || this.defaultLocale;
  }

  getUserCurrency(): string {
    return this.token.currency || this.defaultCurrency;
  }

  getUserCurrencyDisplay(): string {
    return this.token.currencyDisplay || this.defaultCurrencyDisplay;
  }
}
