import { Model } from './abstract.model';
import { IImage } from '@sirius/common';
import { Moment } from 'moment';

export class Image extends Model<IImage> implements IImage {
    imageID: string;
    imageUri: string;
    imageSize: number;
    imageDateTime: Moment;
    thumb: string;
    alt: string;

    constructor(config: IImage) {
        super(config);
    }

}
