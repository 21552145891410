import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { CoreService } from '../../core/services/core.service';

@Directive({
  selector: 'input[appCurrencyMask]',
  providers: [
    CurrencyPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyMaskDirective),
      multi: true
    },
  ]
})
export class CurrencyMaskDirective implements AfterViewInit, ControlValueAccessor {
  /** Input text align */
  @Input() public align: 'right' | 'left' = 'right';
  @Input() public valueFormatter: (value: number) => string;
  @Input() public valueParser: (value: string) => number;
  public locale: string;
  public currencyCode: string;
  public currencyDisplay: string;
  private _el: HTMLInputElement;
  private _value: number;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (a: any) => void = noop;

  constructor(
    elementRef: ElementRef,
    private _renderer: Renderer2,
    private _currencyPipe: CurrencyPipe,
    private _coreService: CoreService,
  ) {
    this._el = elementRef.nativeElement;
  }

  get value(): number {
    return this._value;
  }

  set value(v: number) {
    if (typeof v !== 'number') {
      throw Error(v);
    }
    this._value = v;
    this._el.value = this._applyMask(this._value);
    this._renderer.setAttribute(this._el, 'value', this._el.value);
    this.onChangeCallback(this.value);
  }

  public static parseFloat(value: string): number {
    const numberValue: number = parseFloat(value.replace(/[^\-0-9\u0660-\u0669\u06F0-\u06F9\.]/g, ''));
    if (!isNaN(numberValue)) {
      return numberValue;
    } else {
      return 0;
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    this._el.value = this.value.toFixed(2);
    this._renderer.setAttribute(this._el, 'value', this._el.value);
    this._el.setSelectionRange(0, this._el.value.length);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.onTouchedCallback();
    this.value = this._parseValue(value);
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    if (event.keyCode === 189 || event.keyCode === 109) {
      event.preventDefault();
    }
  }
  @HostListener('paste', ['$event']) onPaste(event) {
    const pasteData = event.clipboardData.getData('text/plain');
    if (pasteData.match('-')) {
      event.preventDefault();
    }
  }

  ngAfterViewInit() {
    this._el.style.textAlign = this.align;
    this.locale = this._coreService.getUserLocale();
    this.currencyCode = this._coreService.getUserCurrency();
    this.currencyDisplay = this._coreService.getUserCurrencyDisplay();
  }

  writeValue(value: number) {
    if (typeof value === 'number' && value !== this._value) {
      this._value = value;
      this._el.value = this._applyMask(value);
      this._renderer.setAttribute(this._el, 'value', this._el.value);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  private _parseValue(value: string): number {
    if (this.valueParser) {
      return this.valueParser(value);
    }
    return CurrencyMaskDirective.parseFloat(value);
  }

  private _applyMask(value: number): string {
    if (this.valueFormatter) {
      return this.valueFormatter(value);
    }

    return this._currencyPipe.transform(value, this.currencyCode, this.currencyDisplay);
  }
}
