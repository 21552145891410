import { Component, OnInit } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements ILoadingOverlayAngularComp {
  params: ILoadingOverlayParams;

  constructor() { }

  agInit(params: ILoadingOverlayParams): void {
    this.params = params;
  }
}
