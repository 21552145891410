import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

/**
 * Checks if value is greater than 0.
 */
export const greaterThanZero: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const isValid = Number(String(control.value).replace(/[^0-9\u0660-\u0669\u06F0-\u06F9\.]/g, '')) > 0;
  return isValid ? null : { greaterThanZero: true };
};

@Directive({
  selector: '[appGreaterThanZero]',
  providers: [{ provide: NG_VALIDATORS, useExisting: GreaterThanZeroDirective, multi: true }]
})
export class GreaterThanZeroDirective implements Validator {
  @Input('appGreaterThanZero') condition: boolean;

  validate(control: AbstractControl): ValidationErrors {
    if (!!this.condition) {
      return greaterThanZero(control);
    }
    return null;
  }
}
