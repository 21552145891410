import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { NavigationItem } from '../../interfaces/navigation-item.interface';
import { sideNavToggle } from '../../store/core.actions';
import { selectRootSideNavItems, selectSideNavOpen, selectIsMobile } from '../../store/core.selectors';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-main-side-nav',
  templateUrl: './main-side-nav.component.html',
  styleUrls: ['./main-side-nav.component.scss'],
})
export class MainSideNavComponent implements OnInit, OnDestroy {
  public sideNavOpen$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public sideNavItemList$: Observable<NavigationItem[]>;
  jobStats$: Observable<any>;
  public userRoles: string[] = [];
  activeUrl: string;

  constructor(
    private keycloakService: KeycloakService,
    private readonly store: Store,
    public router: Router,
    public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sideNavOpen$ = this.store.select(selectSideNavOpen);
    this.isMobile$ = this.store.select(selectIsMobile);
    this.sideNavItemList$ = this.store.select(selectRootSideNavItems);
    this.userRoles = this.keycloakService.getUserRoles(true);
    this.activeUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = event.url.split('/')[1];
      }
    });
  }

  ngOnDestroy(): void {
    this.sideNavOpen$ = this.store.select(selectSideNavOpen);
  }

  hasRole(roles?: string[]): boolean {
    if (roles && roles.length > 0) {
      return roles.every(role => this.userRoles.includes(role));
    } else {
      return true;
    }
  }

  onMobileClick() {
    this.isMobile$.subscribe((data) => {
      if (data) { this.store.dispatch(sideNavToggle()); }
    }).unsubscribe();
  }

  hideOnRouteMobile() {
    let showElement = true;
    this.isMobile$.subscribe((data) => {
      if (data) {
        showElement = /details|addresses|notes|communications|images/.test(this.router.url);
        showElement = !showElement;
      }
    }).unsubscribe();
    return showElement;
  }
}
