import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * Default preload strategy, used to disable or enabled module preload basing on "data.preload" value.
 */
@Injectable()
export class CustomPreloadStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return route.data?.preload === false ? of(null) : load();
  }
}
