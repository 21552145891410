
<div class="about-dialog__logo"><img [src]="logoUrl" alt="AutoFlow ltd." /></div>
<h2 mat-dialog-title>{{'about-dialog.title' | translate}}</h2>
<mat-dialog-content>
    <div class="about-dialog__site-codes">
        <div *ngIf="siteCodes.length">
            <h4 *ngIf="siteCodes.length === 1">{{'about-dialog.site-code' | translate}}:</h4>
            <h4 *ngIf="siteCodes.length > 1">{{'about-dialog.sites-code' | translate}}:</h4>
            <div *ngFor="let siteCode of siteCodes">{{siteCode}}</div>
        </div>
    </div>
    <div [innerHTML]="'about-dialog.content' | translate" class="about-dialog"></div>
</mat-dialog-content>