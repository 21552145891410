import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppState } from '../../interfaces/state.interface';
import { Store } from '@ngrx/store';
import { selectIsMobile } from '../../store/core.selectors';
import { Subscriber, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MOBILE_MAX_WIDTH } from '../../core.constants';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  public rightColOpen = window.outerWidth > MOBILE_MAX_WIDTH;

  toggleRightColumn() {
    this.rightColOpen = !this.rightColOpen;
  }

}
