<mat-form-field appearance="outline" floatLabel="never" class="global-search__input" #globalSearchResultsTrigger="matMenuTrigger" [matMenuTriggerFor]="globalSearchResults"
    (click)="$event.preventDefault()">
    <input type="text" [placeholder]="'global-search.placeholder' | translate" aria-label="Number" matInput [formControl]="searchText" autocomplete="off" (click)="openMenu($event)">
    <button matSuffix mat-icon-button *ngIf="!searchText.value.length" class="imports-history-page__search-button">
        <fa-icon icon="search">{{'buttons.search' | translate}}</fa-icon>
    </button>
    <button mat-icon-button matSuffix *ngIf="searchText.value.length" (click)="$event.stopPropagation(); searchText.setValue('')">
        <fa-icon icon="times">{{'buttons.undo' | translate}}</fa-icon>
    </button>
</mat-form-field>
<mat-menu #globalSearchResults="matMenu" class="global-search__results" [overlapTrigger]="false" (closed)="globalSearchFocus = false">
    <app-recent *ngIf="!searchText.value.length"></app-recent>
    <app-search-results *ngIf="searchText.value.length" [searchResults]="searchResults" [loading]="loading" (fetchMore)="fetchMore()"></app-search-results>
</mat-menu>

