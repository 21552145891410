<div class="comm-item" (click)="onClick($event)" *ngIf="item">
  <div class="comm-item__avatar" *ngIf="hasProviderIcon" [style]="providerIconStyle$ | async"></div>
  <div class="comm-item__content">
    <p>
      <span class="comm-item__name">{{item.data.createdBy}}</span> sent a communication for <span class="vehicle-reg">{{item.data.vehicleReg}}</span>
    </p>
    <div class="comm-item__footer">
      <span>{{item.data.createdAt | date:DATE_TIME_FORMAT }}</span>
    </div>
  </div>
</div>