<div class="layout">
  <div class="layout__header">
    <app-header></app-header>
  </div>
  <div class="layout__container">
    <div class="layout__left-col">
      <app-main-side-nav></app-main-side-nav>
    </div>
    <div class="layout__body" [class.layout__body--full]="true">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
