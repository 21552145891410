<div class="main-side-nav" *ngIf="hideOnRouteMobile()" [ngClass]="{'main-side-nav--collapsed': !(sideNavOpen$ | async) && !(isMobile$ | async), 'main-side-nav--mobile': (isMobile$ | async)}">
    <mat-nav-list class="main-side-nav__menu-panel" [class.main-side-nav__menu-panel--mobile]="(isMobile$ | async)">
        <ng-container *ngFor="let link of sideNavItemList$ | async">
            <a mat-list-item class="main-side-nav__button" *ngIf="hasRole(link.roles)" [matTooltipDisabled]="(sideNavOpen$ | async)" matTooltipPosition="right"
            routerLinkActive="main-side-nav__button--active" [class.main-side-nav__button--sub-active]="link.url.split('/')[1] === activeUrl" matTooltip="{{link.tooltip | translate}}" [routerLink]="link.url" (click)="onMobileClick()">
                <h3 matListItemTitle color="primary" [class.main-side-nav__button-text--hidden]="!(sideNavOpen$ | async) && (isMobile$ | async)" [class.main-side-nav__button-text--mobile]="(isMobile$ | async)" class="main-side-nav__button-text">{{link.label | translate }}</h3>
                <div matListItemIcon class="main-side-nav__icon" [class.main-side-nav__icon--mobile]="(isMobile$ | async)">
                    <fa-layers>
                        <fa-icon class="main-side-nav__icon-{{link.icon}}" [icon]="['fas', link.icon]" fixedWidth="true" [ngClass]="{'main-side-nav__icon--no-offers': link.icon ===  'hand-holding' && !(link.count) }"></fa-icon>
                        <fa-layers-counter class="fa-layers-counter--badges" *ngIf="link.count" [content]="link.count"></fa-layers-counter>
                    </fa-layers>
                </div>
            </a>
        </ng-container>
    </mat-nav-list>
</div>