/* eslint-disable @typescript-eslint/naming-convention */
import { KeyValue } from '@angular/common';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { IImage } from '@sirius/common';
import { ThumbnailConfig } from '../interfaces/thumbnail-config';
import { cloneDeep } from 'lodash';
import { MOMENT_DATE_TIME } from '../core.constants';
import moment from 'moment';

export abstract class Helper {
    public static readonly DEBOUNCE_TIME = 200;
    public static readonly TIME_FORMAT = 'HH:mm';
    public static readonly DATE_FORMAT = 'EEE, d MMMM, y';
    public static readonly MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
    /** ! Not for a moment object */
    public static readonly DATE_TIME_FORMAT = `${Helper.DATE_FORMAT} ${Helper.TIME_FORMAT}`;
    public static readonly NA = 'N/A';
    public static readonly EMAIL_PATTERN = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    public static readonly PHONE_NO_PATTERN = /^(\+)?([ 0-9])+$/;
    public static readonly CURRENCY_PATTERN = /^$|^\d+(?:\.\d{1,2})?$/;
    // eslint-disable-next-line max-len
    public static readonly UK_POST_CODE = /^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/;

    /**
     *
     *
     * Finds element of object by key and replace found by provided replacement
     *
     *
     *
     * @static
     * @template T
     * @param collection
     * @param replacement
     * @param key
     * @returns
     * @memberof Helper
     */
    public static findAndReplace<T>(collection: T[], target: T, key: keyof T) {
        return collection.reduce((acc, item) => {
            if (target[key] !== item[key]) {
                acc.push(item);
            }
            return acc;
        }, [target]);
    }

    /**
     *
     *
     * Returns new collection without provided element
     *
     *
     *
     * @static
     * @template T
     * @param collection
     * @param target
     * @param key
     * @returns
     * @memberof Helper
     */
    public static findAndDelete<T>(collection: T[], keyValue: any, key: keyof T) {
        return collection.reduce((acc, item) => {
            if (keyValue !== item[key]) {
                acc.push(item);
            }
            return acc;
        }, []);
    }

    /**
     *
     *
     * Transforms image load callback in to easy to use promise
     *
     *
     *
     * @static
     * @param imageSrc
     * @returns
     * @memberof Helper
     */
    public static async loadImage(imageSrc: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(imageSrc);
            };
            img.onerror = (e) => {
                reject(e);
            };
            img.src = imageSrc;
        });
    }

    /**
     *
     *
     * Return a thumbnail url from an IImage
     *
     *
     *
     * @static
     * @param img
     * @returns
     * @memberof Helper
     */
    // preps the thumbnail gallery images URLs
    public static thumbnailUrl(img: IImage, config?: Partial<ThumbnailConfig>) {
        const _config: ThumbnailConfig = {
            scheme: 'https',
            storageAccount: 'siriusfiles',
            blobStorageUrl: 'blob.core.windows.net',
            container: 'thumbnails',
            size: 224,
        };
        config = Object.assign(_config, config);
        config.baseUrl = `${config.scheme}://${config.storageAccount}.${config.blobStorageUrl}/${config.container}`;
        const filename = img.imageUri.split('/').splice(-1)[0];
        return `${_config.baseUrl}/${config.size}/${filename}`;
    }
    // preps the fullsize gallery image URL
    public static imagesUrl(imgUri: string): string {
        const imageConfig = {
            scheme: 'https',
            storageAccount: 'siriusfiles',
            blobStorageUrl: 'blob.core.windows.net',
            container: 'images',
        };
        const baseUrl = `${imageConfig.scheme}://${imageConfig.storageAccount}.${imageConfig.blobStorageUrl}/${imageConfig.container}`;
        const filename = imgUri.split('/').splice(-1)[0];
        return `${baseUrl}/${filename}`;
    }

    /**
     *
     *
     * Iterate through form group controls.
     *
     *
     *
     * @static
     * @param form
     * @returns
     * @memberof Helper
     */
    public static *formGroupIterator(form: UntypedFormGroup): Iterable<AbstractControl> | undefined {
        if (!form) { return; }
        for (const controlKey in form.controls) {
            if (form.controls.hasOwnProperty(controlKey)) {
                const control = form.controls[controlKey];
                yield control;

                if (control instanceof UntypedFormGroup) {
                    yield* Helper.formGroupIterator(control);
                }
            }
        }
    }

    /**
     *
     *
     * Transforms key value object in to array of key value objects
     *
     *
     *
     * @static
     * @template T
     * @param obj
     * @returns
     * @memberof Helper
     */
    public static objToKeyValue<T>(obj: { [key: string]: T }): Array<KeyValue<string, T>> {
        return Object.keys(obj).map((key) => ({ key, value: obj[key] }));
    }

    /**
     *
     *
     * Sum all object number values
     *
     *
     *
     * @deprecated not in use
     * @static
     * @param obj
     * @returns
     * @memberof Helper
     */
    public static sum(obj: { [key: string]: number | string }, decimals = 2): number {
        const sum = Object.values(obj).reduce<number>((acc, v) => {
            if (+v) {
                acc += +v;
            }
            return acc;
        }, 0);
        const pow = Math.pow(10, decimals);
        return Math.round(sum * pow) / pow;
    }

    /**
     *  Generates uuid
     */
    public static uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            // eslint-disable-next-line
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    /**
     * Generate MongoDB ID
     */
    public static mongoDbId() {
        // eslint-disable-next-line no-bitwise
        const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () =>
            // eslint-disable-next-line no-bitwise
            (Math.random() * 16 | 0).toString(16)
        ).toLowerCase();
    }

    /**
     * Clone deep and removes object restrictions.
     */
    public static cloneDeep<T>(data: T): T {
        return cloneDeep(data);
    }

    /**
     * Returns moment or null if date is invalid.
     */
    public static momentOrNull(date: any): moment.Moment | null {
        return date && moment(new Date(date)).isValid() ?
            moment(new Date(date)) : null;
    }

    public static serialize<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

}
