import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
// eslint-disable-next-line max-len
import { CommunicationNotificationItemComponent } from './components/communication-notification-item/communication-notification-item.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsEffects } from './store/notifications.effects';
import { notificationsReducer, NOTIFICATIONS_STATE_KEY } from './store/notifications.reducer';
import { NotificationsSocketService } from './services/notifications-socket.service';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationsListComponent,
    CommunicationNotificationItemComponent
  ],
  exports: [
    NotificationsComponent,
    NotificationsListComponent,
    CommunicationNotificationItemComponent
  ],
  imports: [
    SharedModule,
    MatMenuModule,
    MatBadgeModule,
    StoreModule.forFeature(NOTIFICATIONS_STATE_KEY, notificationsReducer),
    EffectsModule.forFeature([NotificationsEffects]),
  ],
  providers: [
    NotificationsSocketService,
  ],
})
export class NotificationsModule { }
