<form class="address-form" *ngIf="address" [formGroup]="addressForm">
    <div class="address-form__card" [ngClass]="isEditable?'address-form__card--editable':''">
        <app-grid-layout [minMaxWidth]="350">
            <div class="address-form__card-column">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.type' | translate}}</mat-label>
                    <mat-select required matInput formControlName="type" placeholder="Select address type">
                        <mat-option [value]="value" *ngFor="let value of addressTypes">{{
                            'address-form.address-types.' + value | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.title' | translate}}</mat-label>
                    <input matInput formControlName="title" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.firstName' | translate}}</mat-label>
                    <input matInput formControlName="firstName" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.lastName' | translate}}</mat-label>
                    <input matInput required formControlName="lastName" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.companyName' | translate}}</mat-label>
                    <input matInput formControlName="companyName" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.addressLine1' | translate}}</mat-label>
                    <input matInput formControlName="addressLine1" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.addressLine2' | translate}}</mat-label>
                    <input matInput formControlName="addressLine2" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.addressLine3' | translate}}</mat-label>
                    <input matInput formControlName="addressLine3" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.town' | translate}}</mat-label>
                    <input matInput formControlName="town" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.country' | translate}}</mat-label>
                    <input matInput formControlName="country" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.postcode' | translate}}</mat-label>
                    <input matInput formControlName="postcode" />
                </mat-form-field>
            </div>
            <div class="address-form__card-column">
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.telephone1' | translate}}</mat-label>
                    <input matInput formControlName="telephone1" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.telephone2' | translate}}</mat-label>
                    <input matInput formControlName="telephone2" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.fax' | translate}}</mat-label>
                    <input matInput formControlName="fax" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.mobile' | translate}}</mat-label>
                    <input matInput formControlName="mobile" />
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{'address-form.email' | translate}}</mat-label>
                    <input matInput formControlName="email" />
                </mat-form-field>
            </div>
        </app-grid-layout>
    </div>
</form>
