<mat-toolbar class="header mat-elevation-z2">
    <button *ngIf="!(isMobile$ | async)" class="header__toggle-button" [class.header__toggle-button--collapsed]="!(sideNavOpen$ | async)" mat-icon-button color="primary" (click)="toggleMainSideNav()"
        [attr.aria-label]="'header.toggle' | translate">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
    </button>
    <img class="header__logo" [src]="logoUrl" alt="AutoFlow ltd." />
    <div class="header__customer-name">{{siteName}}</div>
    <div class="header__actions">
        <div class="header__global-search">
            <app-global-search></app-global-search>
        </div>
        <div class="header__alert-icons">
            <app-notifications></app-notifications>
        </div>
        <div class="header__user-menu">
            <app-user-menu></app-user-menu>
        </div>
    </div>
</mat-toolbar>
