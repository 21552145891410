/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';
import { NoteType } from '@sirius/common';

// eslint-disable-next-line no-shadow
enum NoteTypeTag {
  'GENERAL' = 'job-notes-page__note.general',
  'COMMENTS' = 'job-notes-page__note.comments',
  'GENERAL COMMENTS' = 'General Comments',
  'CIRCUMSTANCES' = 'job-notes-page__note.circumstances',
  'DAMAGE DESCRIPTION' = 'job-notes-page__note.damageDescription',
  'SPECIAL INSTRUCTIONS' = 'job-notes-page__note.specialInstructions',
  'RECOVERY INSTRUCTIONS' = 'job-notes-page__note.recoveryInstructions',
  'VEHICLE LOCATION' = 'job-notes-page__note.vehicleLocation',
}

@Pipe({
  name: 'noteType'
})
export class NoteTypePipe implements PipeTransform {

  transform(value: NoteType): NoteTypeTag {
    return NoteTypeTag[value];
  }

}
