import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly keycloakService: KeycloakService,
    private route: ActivatedRoute) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (next.data.roles.every((role: string) => this.keycloakService.getUserRoles(true).includes(role))) {
      return true;
    } else {
       if (state.url === '/users') {this.router.navigateByUrl('/offers');}
       else {this.router.navigateByUrl('/404');};
    }
  }

}
