
export const BUTTON_TEXT = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DISMISS: 'Dismiss',
};

export const APP_MSG = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LOCATION_ACCESS_DENIED: 'You do not have permission to access that location',
};

export const MOMENT_DATE_TIME = 'ddd, D MMMM, YYYY HH:mm';
export const MOMENT_DATE = 'ddd, D MMMM, YYYY';

export const MOBILE_MAX_WIDTH = 600;
export const TABLET_MAX_WIDTH = 1024;
export const JOB_DETAILS_MOBILE_MAX = 1130;
export const DEFAULT_CURRENCY_DISPLAY = 'DEFAULT_CURRENCY_DISPLAY';
