import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class WebsocketAuthService {
  $authRequired = this.socket.fromEvent<any>('AUTHENTICATION_REQUIRED').pipe(
    map(_ => {
      console.log('[WS] Get Authentication required');
      const token = this.keycloak.getKeycloakInstance().token;
      if (token) {
        this.authenticate(token);
      }
    })
  );

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly socket: Socket,
  ) {}

  /**
   * Send the JWT via WebSocket
   *
   * @memberof NotificationsSocketService
   */
  authenticate(token: string) {
    console.log('[WS] Authenticate request received', token);
    this.socket.emit('AUTHENTICATE', { token });
  }

}
