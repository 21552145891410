<ng-container *ngIf="(notificationsCount$ | async); let notifyCount; else noBadgeIcon">
    <button [@bell]="notifyCount" class="notifications__toggle-button" [value]="notifyCount" color="primary" mat-icon-button [matMenuTriggerFor]="messageList" matTooltip="{{'notifications.button' | translate}}">
        <span [matBadge]="notifyCount > 9 ? '9+' : notifyCount" matBadgeColor="warn"><fa-icon [icon]="['fas', 'bell']"></fa-icon></span>
    </button>
</ng-container>
<ng-template #noBadgeIcon>
    <button class="notifications__toggle-button notifications__toggle-button--no-badge" color="primary" mat-icon-button>
        <fa-icon [icon]="['fas', 'bell']"></fa-icon>
    </button>
</ng-template>
<mat-menu #messageList="matMenu" class="notifications__menu">
    <h2 class="notifications__title">{{'notifications.title' | translate}}</h2>
    <mat-divider></mat-divider>
    <app-notifications-list [notifications]="notifications$ | async"></app-notifications-list>
    <mat-divider></mat-divider>
</mat-menu>