import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { JobStateType } from '@sirius/common';
import { ColumnState, IGetRowsParams, IServerSideGetRowsParams } from 'ag-grid-community';
import { Communication } from '../..//core/models/communication.model';
import { Address } from '../../core/models/address.model';
import { Job } from '../models/job.model';

export const loadJobs = createAction('[jobs/API] Load Jobs 2', props<{ params: IServerSideGetRowsParams }>());
export const loadJobsSuccess = createAction('[jobs/API] Load Jobs 2 Success', props<{ jobs: Job[] }>());
export const loadJobsError = createAction('[jobs/API] Load Jobs 2 Error', props<{ error: Error }>());

export const loadJob = createAction('[jobs/API] Load Job', props<{ id: string }>());
export const loadJobError = createAction('[jobs/API] Load Job Error', props<{ jobId: string; error: any }>());
export const loadJobSuccess = createAction('[jobs/API] Load Job Success', props<{ job: Job }>());

export const createJobSuccess = createAction('[jobs/API] Create Job Success', props<{ job: Job }>());
export const updateJob = createAction('[jobs/API] Update Job', props<{ jobUpdate: Update<Job> }>());
export const updateJobSuccess = createAction('[jobs/API] Update Job Success', props<{ jobUpdate: Update<Job> }>());
export const updateJobError = createAction('[jobs/API] Update Job Error', props<{ id: string }>());
export const clearJobSlice = createAction('[jobs/API] Clear Job Slice');

export const updateMainContact = createAction('[jobs/Page] Set Main Contact', props<{ addressId: string; isMainContact: boolean }>());
export const updateMainContactSuccess = createAction('[jobs/Page] Set Main Contact Success');
export const updateMainContactError = createAction('[jobs/Page] Set Main Contact Error');
export const setColumnState = createAction('[jobs/Grid] Update Column Definition', props<{
  columnStates: ColumnState[];
  selectedStatus: JobStateType | 'ALL';
}>());
export const resetColumnState = createAction('[jobs/Grid] Reset Column Definition', props<{ selectedStatus: JobStateType | 'ALL' }>());
export const setSelectedStatus = createAction('[jobs/Gird] Set Status Filter', props<{ selectedStatus: JobStateType | 'ALL' }>());
export const loadFilterValues = createAction('[jobs/Gird] Load Job Grid Filter Values');
export const loadFilterValuesSuccess = createAction('[jobs/Gird] Load Job Grid Filter Values Success', props<{
  filterValues: { [key: string]: string[] };
}>());
export const loadFilterValuesError = createAction('[jobs/Gird] Load Job Grid Filter Values Error', props<{ error: Error }>());

export const setSelectedJobId = createAction('[jobs/Page] Set Selected Job Id', props<{ selectedJobId: string }>());
export const toggleJobsSideNav = createAction('[jobs/Page] Toggle side navigation menu');
export const toggleJobSideNav = createAction('[job/Page] Toggle side navigation menu');

export const setAddressPageIsEditable = createAction('[job/address/Page] make editable', props<{ isEditable: boolean }>());
export const setAddressPageIsBusy = createAction('[job/address/Page] make busy', props<{ isBusy: boolean }>());
export const updateJobAddress = createAction('[job/address/Page] Update Address', props<{
  jobId: string;
  addressId: string;
  changes: Partial<Address>;
}>());
export const updateJobAddressSuccess = createAction('[job/address/Page] Update Address Success', props<{
  jobId: string;
  addressId: string;
  changes: Partial<Address>;
}>());
export const updateJobAddressError = createAction('[job/address/Page] Update Address Error', props<{ jobId: string; addressId: string }>());
export const deleteJobAddress = createAction('[job/address/Page] Delete Address', props<{ jobId: string; addressId: string }>());
export const deleteJobAddressError = createAction('[job/address/Page] Delete Address Error', props<{ jobId: string; addressId: string }>());
export const addJobAddress = createAction('[job/address/Page] Add Address', props<{ jobId: string; address: Address }>());
export const addJobAddressError = createAction('[job/address/Page] Add Address Error', props<{ jobId: string; error: Error }>());
export const addJobAddressSuccess = createAction('[job/address/Page] Add Address Success', props<{ jobId: string; address: Address }>());

export const setJobDetailsIsEditable = createAction('[job/DetailsForm] Set edit state', props<{ isEditable: boolean }>());
export const setJobDetailsIsBusy = createAction('[job/DetailsForm] Set busy state', props<{ isBusy: boolean }>());

export const addJobCommunication = createAction('[job/comms/Form] Add communications', props<{
  jobId: string;
  communication: Communication;
}>());
export const addJobCommunicationSuccess = createAction('[job/comms/Form] Add communications Success',
  props<{ jobId: string; communication: Communication }>()
);
export const addJobCommunicationError = createAction('[job/comms/Form] Add communications Error');
export const revealJobCommunication = createAction('[Job/Comms] Add Communication',
  props<{ jobId: string; commId: string; query: string }>(),
);

export const setCommsPageIsBusy = createAction('[job/comms/Page] make busy', props<{ isBusy: boolean }>());
export const setCommsPageIsBusySuccess = createAction('[job/comms/Page] make busy success');
export const onAddressPageDestroy = createAction('[job/comms/Page] on destroy', props<{jobStatus: string}>());
export const onSaveAddressPage = createAction('[job/comms/Page] on save', props<{
  jobId: string;
  addressId: string;
  changes: Partial<Address>;
}>());
