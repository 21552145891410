/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  combineLatestWith,
  map,
  switchMap,
} from 'rxjs/operators';
import { JobService } from '../../jobs/services/job.service';
import { SearchResult } from '../search-result';
import { loadMoreResults, loadMoreResultsSuccess, loadSearchResults, loadSearchResultsError, triggerSearch } from './global-search.actions';
import { selectGlobalSearchText } from './global-search.selectors';

@Injectable()
export class GlobalSearchEffects {


  triggerSearch$ = createEffect(() => this.actions$.pipe(
    ofType(triggerSearch),
    switchMap(({ searchText }) => {
      if (!searchText) {
        return [loadSearchResults({ searchResults: [] })];
      }

      const params = this._createParams(searchText, 0, 50) as any;
      return this.jobService.getJobs(params).pipe(
        map(({ jobs }) => jobs.map(job => new SearchResult(job))),
        map(searchResults => loadSearchResults({ searchResults })),
        catchError(error => [loadSearchResultsError({ error })]),
      );
    }),
  ));

  loadMoreResults$ = createEffect(() => this.actions$.pipe(
    ofType(loadMoreResults),
    // combines actions$ with store value into an array
    combineLatestWith(this.store.select(selectGlobalSearchText)),
    switchMap(([bounds, searchText]) => {
      const params = this._createParams(searchText, bounds.start, bounds.end) as any;
      return this.jobService.getJobs(params).pipe(
        map(({ jobs }) => jobs.map(job => new SearchResult(job))),
        map(searchResults => loadMoreResultsSuccess({ searchResults })),
        catchError(error => [loadSearchResultsError({ error })]),
      );
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly jobService: JobService,
    private readonly store: Store,
  ) { }

  private _createParams(searchText: string, startRow: number, endRow: number) {
    return {
      searchValue: searchText,
      startRow,
      endRow,
      sortModel: [{ colId: 'updatedAt', sort: 'desc' }],
      filterModel: {},
      rowGroupCols: [],
      valueCols: [],
      pivotCols: [],
      pivotMode: false,
      groupKeys: [],
    };
  }
}

