<h2 mat-dialog-title>{{data.title | translate}}</h2>
<mat-dialog-content>
    <p>{{data.description | translate}}</p>
    <form [formGroup]="reasonsForm">
        <mat-form-field appearance="outline">
            <mat-label>{{'reason-dialog.selectReason' | translate}}</mat-label>
            <mat-select formControlName="reason">
                <mat-option *ngFor="let reason of reasons" [value]="reason">{{reason}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>{{'reason-dialog.details' | translate}}</mat-label>
            <textarea rows="2" matInput cdkTextareaAutosize formControlName="details"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="action__button" color="primary" [disabled]="reasonsForm.invalid" (click)="dialogRef.close(reasonsForm.value)">{{data.okButtonText | translate}}</button>
    <button mat-button class="action__button" color="warn" (click)="dialogRef.close()">{{(data.cancelButtonText | translate) || 'buttons.cancel' | translate}}</button>
</mat-dialog-actions>