import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { NotFoundPageComponent } from './core/components/not-found-page/not-found-page.component';
import { UnderMaintenancePageComponent } from './core/components/under-maintenance-page/under-maintenance-page.component';
import { CustomKeycloakAuthGuard } from './core/services/custom-keyclock-auth.service';
import { CustomPreloadStrategy } from './core/strategies/custom-preload.strategy';
import { UserRoleGuard } from './shared/guards/user-role.guard';

const routes: Routes = [
  {
    path: '', canActivate: [CustomKeycloakAuthGuard], component: LayoutComponent, children: [
      { path: '', redirectTo: 'offers', pathMatch: 'full' },
      {
        path: 'cheatsheet',
        loadChildren: () => import('./cheatsheet/cheatsheet.module').then(m => m.CheatsheetModule),
      },
      {
        path: 'offers',
        loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule),
      },
      {
        path: 'jobs',
        loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
      },
      {
        path: 'imports',
        canActivate: [UserRoleGuard],
        data: { preload: false, roles: ['Importer'] },
        loadChildren: () => import('./imports/imports.module').then(m => m.ImportsModule),
      },
      {
        path: 'users',
        canActivate: [UserRoleGuard],
        data: { preload: false, roles: ['Bodyshop Admin'] },
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
      },
    ]
  },
  { path: 'under-maintenance', component: UnderMaintenancePageComponent },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        preloadingStrategy: CustomPreloadStrategy
      }
    )
  ],
  exports: [RouterModule],
  providers: [CustomKeycloakAuthGuard, CustomPreloadStrategy]
})
export class AppRoutingModule { }
