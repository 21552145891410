import { createAction, props } from '@ngrx/store';
import { Communication } from '../../core/models/communication.model';

export const addCommunications = createAction('[Communication] Add Many Communications', props<{ communications: Communication[] }>());
export const addComm = createAction('[Communication] Add Communication', props<{ communication: Communication; jobId: string }>());
export const revealCommunication = createAction('[Communication] Reveal Communication',
  props<{ siteCode: string; communicationId: string; jobId: string }>());
export const revealCommunicationSuccess = createAction('[Communication] Reveal Communication Success',
  props<{ commId: string; query: string }>(),
);
export const revealCommunicationError = createAction('[Communication] Reveal Communication Error', props<{ commId: string }>());
