<div class="job-card" *ngIf="job">
  <div class="job-card__header">
    <div class="job-card__job-info">
      <span class="job-card__chip job-card__status">
        {{job.state}}
      </span>
      <span class="job-card__chip job-card__site" *ngIf="job.site.shortName">
        <span>{{job.site.shortName}}</span>
      </span>
      <div class="job-card__vehicle-info">
        <span>{{job.vehicleDetails.make}}</span><span> - </span><span>{{job.vehicleDetails.model}}</span>
      </div>
    </div>
    <div class="job-card__vehicle-logo" [style]="vehicleIconStyle$ | async"></div>
  </div>
  <div class="job-card__content">
    <div class="job-card__row">
      <span class="job-card__span--bold">{{job.vehicleDetails.reg}}</span><span> - </span><span>{{job.vehicleDetails.colour}}</span><span> - </span>
      <span class="job-card__span--bold">{{mainContact}}</span><span> - </span><span>{{job.workProvider.name}}</span>
    </div>
  </div>
</div>