import { Injectable,  } from '@angular/core';
import { Observable, fromEvent, map, debounceTime, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class WindowSizeService {
  windowDimensions: Observable<{width: number; height: number}>;

  constructor() {
    const dimensions = fromEvent(window, 'resize').pipe(
      startWith({
        width: window.innerWidth,
        height: window.innerHeight
      }),
      debounceTime(100), map(()=> ({
      width: window.innerWidth,
      height: window.innerHeight
    })));
    this.windowDimensions = dimensions;
  }
}
