import { createAction, props } from '@ngrx/store';
import { SearchResult } from '../search-result';

export const triggerSearch = createAction('[Global Search Module] Begins to search for jobs.', props<{ searchText: string }>());
export const loadSearchResults = createAction('[Global Search Module] Replaces stored search results with new one.',
  props<{ searchResults: SearchResult[] }>()
);
export const loadSearchResultsError = createAction('[Global Search Module] Loading search results error.',
  props<{ error: Error }>()
);
export const loadMoreResults = createAction('[Global Search Module] Trigger search from more results.',
  props<{ start: number; end: number }>());
export const loadMoreResultsSuccess = createAction('[Global Search Module] Adds new results to existing.',
  props<{ searchResults: SearchResult[] }>()
);
