import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms -1 value to TBA
 *
 * @export
 * @class TbaPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'tba'
})
export class TbaPipe implements PipeTransform {
  transform(value: number | string): typeof value | 'TBA' {
    return (value === 0) || (value > 0) || (value === '0') ? +value : 'TBA';
  }
}
