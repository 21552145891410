import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Notification } from '../models/notification.model';
import * as NotificationsActions from './notifications.actions';

export const NOTIFICATIONS_STATE_KEY = 'notifications';

export interface NotificationsState extends EntityState<Notification> {
  loading: boolean;
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: ins => ins._id
});

export const initialState: NotificationsState = adapter.getInitialState({
  loading: false,
});

export const _notificationsReducer = createReducer(initialState,
  on(NotificationsActions.loadNotifications, (state: NotificationsState): NotificationsState => ({ ...state, loading: true })),
  on(NotificationsActions.loadNotificationsSuccess, (state: NotificationsState, { notifications }) =>
    adapter.setAll(notifications, { ...state, loading: false })),
  on(NotificationsActions.loadNotificationsError, (state: NotificationsState): NotificationsState => ({ ...state, loading: false })),
  on(NotificationsActions.createNotification, (state: NotificationsState, { notification }) => adapter.addOne(notification, state)),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function notificationsReducer(state, action) {
  return _notificationsReducer(state, action);
}

export const notificationsSelectors = adapter.getSelectors();
