import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, takeUntil, throttleTime } from 'rxjs/operators';
import { SearchResult } from '../search-result';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;
  @Input() searchResults: SearchResult[] = [];
  @Input() throttleTime = 400;
  @Input() loading: boolean;
  @Output() fetchMore = new EventEmitter();
  public readonly listItemHeight = 111;
  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private readonly ngZone: NgZone,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initScroller();
  }

  initScroller() {
    this.scroller.elementScrolled().pipe(
      takeUntil(this.destroyed$),
      map(() => this.scroller.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => (y2 < y1 && y2 < this.listItemHeight * 1.5)),
      throttleTime(this.throttleTime),
      map(v => v.toString()),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.ngZone.run(() => {
        this.fetchMore.emit();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
