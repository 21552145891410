/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorHandler, Injectable } from '@angular/core';

declare let dataLayer;

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {

  handleError(error: any = {}) {
    dataLayer.push({
      event: 'Unhandled Exception',
      'gtm.errorLineNumber': error.lineNumber,
      'gtm.errorMessage': error.message,
      'gtm.errorUrl': error.fileName,
      errorObject: error,
    });

    super.handleError(error);
  }
}
