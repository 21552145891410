import { Component, OnInit, Input, AfterViewChecked, AfterViewInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Helper } from '../../../core/utils/helper';
import { Job } from '../../../jobs/models/job.model';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent {
  vehicleIconStyle$: Promise<SafeStyle>;
  mainContact: string;

  private _job: Job;

  get job() {
    return this._job;
  }

  @Input() set job(job: Job) {
    if (!job) { return; }
    this._job = job;
    this.vehicleIconStyle$ = this.getMakeIconStyle(this.job.vehicleDetails.make);
    this.mainContact = this.job.addresses.find(address => address.isMainContact)?.fullName || 'Unknown';
  }

  async getMakeIconStyle(make: string): Promise<SafeStyle> {
    try {
      const img = await Helper.loadImage(`../../../assets/images/car-logos/${make.replace(/[ -]/, '_').toUpperCase()}.png`);
      return `
      background-image: url(${img});
      background-size: contain;
      background-repeat: no-repeat;
      background-position:center center;
      `;
    } catch {
      console.warn(`Cannot find ${make} logo.`);
    }
  }

}
