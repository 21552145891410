import { Component, Input, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit, INoRowsOverlayAngularComp {
  @Input() text = 'No results.';
  @Input() titleText = '';

  constructor() { }

  ngOnInit() {
  }

  agInit(params: INoRowsOverlayParams): void {
    this.text = 'jobs-page.noJobsFilter';
  }

}
