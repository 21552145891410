/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CoreService } from '../services/core.service';
import { WebsocketAuthService } from '../services/websocket-auth.service';
import * as CoreActions from './core.actions';

@Injectable()
export class CoreEffects {
loadJobStats$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.loadJobStats),
    mergeMap(_ => this.coreService.getJobStats().pipe(
      map(jobStats => CoreActions.loadJobStatsSuccess({ jobStats })),
      catchError((response) => {
        this.snackBar.open(`Unable to load job statistics.`);
        return [
          CoreActions.loadJobStatsError({ error: response.error }),
        ];
      }),
    ))
  ));

$authRequired  = createEffect(() => this.websocketAuthService.$authRequired.pipe(
    map(_ => CoreActions.authRequired()),
  ));

  constructor(
    private readonly coreService: CoreService,
    private readonly actions$: Actions,
    private readonly websocketAuthService: WebsocketAuthService,
    private readonly snackBar: MatSnackBar,
  ) { }
}
