import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SearchResult } from '../search-result';
import {
  loadMoreResults,
  loadMoreResultsSuccess,
  loadSearchResults,
  loadSearchResultsError,
  triggerSearch,
} from './global-search.actions';

export const GLOBAL_SEARCH_STATE_KEY = 'globalSearch';

export const adapter: EntityAdapter<SearchResult> = createEntityAdapter<SearchResult>({
  selectId: ins => ins.data._id
});

export interface GlobalSearchState extends EntityState<SearchResult> {
  loading: boolean;
  searchText: string;
};

export const initialState: GlobalSearchState = adapter.getInitialState({
  loading: false,
  searchText: '',
});

export const _globalSearchReducer = createReducer(initialState,
  on(loadSearchResults, (state: GlobalSearchState, { searchResults }): GlobalSearchState => adapter.setAll(
    searchResults, { ...state, loading: false }
  )),
  on(loadSearchResultsError, (state: GlobalSearchState, { error }): GlobalSearchState => ({ ...state, loading: false })),
  on(loadMoreResults, (state: GlobalSearchState): GlobalSearchState => ({ ...state, loading: true })),
  on(triggerSearch, (state: GlobalSearchState, { searchText }): GlobalSearchState => (
    adapter.setAll([], { ...state, searchText, loading: true })
  )),
  on(loadMoreResultsSuccess, (state: GlobalSearchState, { searchResults }) => adapter.addMany(searchResults, { ...state, loading: false })),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function globalSearchReducer(state, action) {
  return _globalSearchReducer(state, action);
}

export const globalSearchSelectors = adapter.getSelectors();
