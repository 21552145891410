<button class="user-menu__toggle-button" color="primary" mat-icon-button [matTooltip]="currentUser" matTooltipPosition="below" [matMenuTriggerFor]="userMenu" [attr.aria-label]="'user-menu.toggle' | translate">
    <fa-icon [icon]="['fas', 'user']"></fa-icon>
</button>
<mat-menu #userMenu="matMenu" class="user-menu__menu">
    <div class="user-menu__dropdown">
        <h3 aria-label="username">
            <span>{{currentUser}}</span>
        </h3>
        <mat-divider></mat-divider>
        <mat-action-list class="user-menu__list">
            <button mat-list-item (click)="about()" aria-label="log out">
                <fa-icon matListIcon icon="question"></fa-icon>
                {{'buttons.about' | translate}}
            </button>
        </mat-action-list>
        <mat-action-list class="user-menu__list">
            <button mat-list-item (click)="logout()" aria-label="log out" class="user-menu__logout-button">
                <fa-icon matListIcon icon="sign-out"></fa-icon>
                <span>{{'buttons.logout' | translate}}</span>
            </button>
        </mat-action-list>
        <mat-action-list class="user-menu__list" *ngIf="isAdmin()">
            <button mat-list-item aria-label="user management" class="user-menu__logout-button" routerLink="/users">
                <fa-icon matListIcon icon="cog"></fa-icon>
                <span>{{'buttons.user-admin' | translate}}</span>
            </button>
        </mat-action-list>
    </div>
</mat-menu>