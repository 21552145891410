import { Component, Input, OnInit } from '@angular/core';
import { Image } from '../../../core/models/image.model';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Helper } from '../../../core/utils/helper';

@Component({
  selector: 'app-images-list',
  templateUrl: './images-list.component.html',
  styleUrls: ['./images-list.component.scss']
})
export class ImagesListComponent implements OnInit {
  @Input() images: Image[] = [];
  @Input() minMaxWidth = 290;
  galleryItems: GalleryItem[];

  constructor(public gallery: Gallery) { }

  ngOnInit(): void {
    this.galleryItems = this.images.map(image => new ImageItem(
      { src: Helper.imagesUrl(image.imageUri), thumb: Helper.imagesUrl(image.imageUri), }
    ));
    const galleryRef = this.gallery.ref();
    galleryRef.load(this.galleryItems);
  }
};
