import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  title: string;
  message?: string;
  disagreeBtnText?: string;
  agreeBtnText?: string;
  disagreeBtnColour?: string;
  agreeBtnColour?: string;
  thirdBtnText?: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public data: ConfirmationDialogData;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogData,
    public readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.data = {
      title: data.title,
      message: data.message,
      disagreeBtnText: data.disagreeBtnText || 'buttons.disagree',
      agreeBtnText: data.agreeBtnText || 'buttons.agree',
      disagreeBtnColour: data.disagreeBtnColour || 'primary',
      agreeBtnColour: data.agreeBtnColour || 'primary',
      thirdBtnText: data.thirdBtnText,
    };
  }

  ngOnInit() {
    if (!this.data.title) {
      throw new Error('You have to provide a title, for your confirmation dialog.');
    }
  }

  /**
   * Close dialog with decision
   *
   * @param decision
   * @memberof ConfirmationDialogComponent
   */
  close(decision: boolean) {
    this.dialogRef.close(decision);
  }

}
