import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../core/interfaces/state.interface';
import { Notification } from '../../models/notification.model';
import { loadNotifications } from '../../store/notifications.actions';
import { selectNotificationsCount, selectNotificationsSorted } from '../../store/notifications.selector';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [trigger('bell', [
    transition(':increment', [
      animate('4s', keyframes ([
        style({ transform: 'rotate(0)', offset: 0}),
        style({ transform: 'rotate(30deg)', offset: 0.01}),
        style({ transform: 'rotate(-28deg)', offset: 0.03}),
        style({ transform: 'rotate(34deg)', offset: 0.05}),
        style({ transform: 'rotate(-32deg)', offset: 0.07}),
        style({ transform: 'rotate(30deg)', offset: 0.09}),
        style({ transform: 'rotate(-28deg)', offset: 0.11}),
        style({ transform: 'rotate(26deg)', offset: 0.13}),
        style({ transform: 'rotate(-24deg)', offset: 0.15}),
        style({ transform: 'rotate(22deg)', offset: 0.17}),
        style({ transform: 'rotate(-20deg)', offset: 0.19}),
        style({ transform: 'rotate(18deg)', offset: 0.21}),
        style({ transform: 'rotate(-16deg)', offset: 0.23}),
        style({ transform: 'rotate(14deg)', offset: 0.25}),
        style({ transform: 'rotate(-12deg)', offset: 0.27}),
        style({ transform: 'rotate(10deg)', offset: 0.29}),
        style({ transform: 'rotate(-8deg)', offset: 0.31}),
        style({ transform: 'rotate(6deg)', offset: 0.33}),
        style({ transform: 'rotate(-4deg)', offset: 0.35}),
        style({ transform: 'rotate(2deg)', offset: 0.37}),
        style({ transform: 'rotate(-1deg)', offset: 0.39}),
        style({ transform: 'rotate(1deg)', offset: 0.41}),
        style({ transform: 'rotate(0)', offset: 0.43}),
        style({ transform: 'rotate(0)', offset: 1})
      ])),
  ])])],
})
export class NotificationsComponent implements OnInit {
  notifications$: Observable<Notification[]>;
  notificationsCount$: Observable<number>;

  constructor(
    private readonly store: Store,
  ) { }

  ngOnInit() {
    this.store.dispatch(loadNotifications());
    this.notifications$ = this.store.select(selectNotificationsSorted);
    this.notificationsCount$ = this.store.select(selectNotificationsCount);
  }
}
