import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { APP_MSG, BUTTON_TEXT } from '../core.constants';

@Injectable()
export class CustomKeycloakAuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService, private authSnack: MatSnackBar) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloakAngular.login();
      return false;
    }

    const requiredRoles = route.data.roles;
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    }

    if (!this.roles || this.roles.length === 0) {
      this.showErrorSnackBar();
      return true;
    }

    let granted = false;
    for (const requiredRole of requiredRoles) {
      if (this.roles.indexOf(requiredRole) > -1) {
        granted = true;
        break;
      }
    }
    if (granted === false) {
      this.showErrorSnackBar();
    }

    return granted;
  }

  /**
   * Display Material SnackBar message to user
   * if permission to access the route is denied
   *
   */
  public showErrorSnackBar(): void {
    this.authSnack.open(APP_MSG.LOCATION_ACCESS_DENIED, BUTTON_TEXT.DISMISS, { duration: 9001 });
  }
}
