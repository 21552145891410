/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export const createUrl = <T>(url) => ({ url } as T & { url?: string });

export class ApiUrls {
  JOBS_OFFERS = createUrl('/jobs/offers');
  JOBS_STATS = createUrl('/jobs/totals');
  JOBS_SEARCH = createUrl('/jobs/search');
  JOBS = createUrl('/jobs');
  JOB = createUrl<{ ':id': string }>('/jobs/:id');
  JOB_REJECT = createUrl<{ ':id': string }>('/jobs/:id/reject');
  JOB_ACCEPT = createUrl<{ ':id': string }>('/jobs/:id/accept');
  JOB_UPDATE_MAIN_CONTACT = createUrl<{ ':id': string }>('/jobs/:id/addresses/update-main-contact');
  JOB_ADDRESSES = createUrl<{ ':id': string }>('/jobs/:id/addresses');
  JOB_ADDRESS = createUrl<{ ':id': string; ':addressId': string }>('/jobs/:id/addresses/:addressId');
  JOB_FILTER_VALUES = createUrl('/jobs/filter-values');
  JOB_COMMUNICATIONS_ADD = createUrl<{ ':id': string }>('/jobs/:id/communications');
  JOB_COMMUNICATIONS_REVEAL = createUrl('/communications/reveal');
  COMMUNICATIONS = createUrl('/communications');
  REJECTION_REASONS = createUrl('/rejection-reasons');
  IMPORTS = createUrl('/imports');
  IMPORTS_RUN = createUrl<{ ':id': string }>('/imports/:id/run');
  IMPORTS_UPDATE = createUrl<{ ':id': string }>('/imports/:id');
  IMPORTS_PUBLISH = createUrl<{ ':id': string }>('/imports/:id/publish');
  IMPORTS_DELETE = createUrl<{ ':id': string }>('/imports/:id/delete');
  IMPORTS_WELCOME_EMAILS = createUrl<{ ':id': string }>('/imports/:id/send-welcome-emails');
  FEATURES = createUrl('/features');
  USERS_LIST = createUrl('/siteadmin/users');
  CREATE_USER = createUrl('/siteadmin/users');
  UPDATE_USER = createUrl('/siteadmin/users');
  USER_SITES = createUrl<{ ':id': string }>('/siteadmin/users/:id/sites');
  GET_USER_BY_ID = createUrl<{ ':id': string }>('/siteadmin/users/:id');
  RESEND_EMAIL = createUrl<{ ':id': string }>('/siteadmin/users/:id/resetPasswordEmail');
}

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  actions = new ApiUrls();

  constructor() { }

  /**
   * Returns full url endpoint path (with api url)
   * also can inject values from inject object in to url string
   * TODO: support for optional params in url
   *
   * @param url
   * @param inject values from this object will be injected in to url string
   * @returns
   * @memberof UrlService
   */
  getUrl<T extends keyof ApiUrls>(endpoint: T, inject?: ApiUrls[T]): string {
    let url = `${environment.apiUrl}${this.actions[endpoint].url}`;
    if (inject) {
      Object.keys(inject).forEach((key) => {
        url = url.replace(key, inject[key]);
      });
    }
    return url;
  }
}
