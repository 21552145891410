import { IAddress, AddressType } from '@sirius/common';
import { isEmpty } from 'lodash';
import { Helper } from '../utils/helper';
import { Model } from './abstract.model';

export class Address extends Model<IAddress> {
    type: AddressType;
    isMainContact: boolean;
    title: string;
    firstName: string;
    lastName: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    town: string;
    country: string;
    postcode: string;
    telephone1: string;
    telephone2: string;
    fax: string;
    mobile: string;
    email: string;
    contact: string;
    contactPreference: number;
    marketingConsent: number;

    constructor(config?: IAddress) {
        super(config);
        if (config && config.type) {
            try {
                this.setType(config.type);
            } catch (e) {
                console.error(e);
            }
        }
    }

    get fullName() {
        const fullName = [this.title, this.firstName, this.lastName].filter(val => !!val).join(' ');
        if (isEmpty(fullName)) {
            return Helper.NA;
        }
        return fullName;
    }

    /**
     *
     *
     * Static constructor for easy loops
     *
     *
     *
     * @example
     * addresses.map(Address.Address)
     *
     * @static
     * @param [config]
     * @returns
     * @memberof Address
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    static Address(config?: IAddress) {
      return new Address(config);
    }

    /**
     *
     *
     * Sets address type to new type, also so security check in case of type is not valid enum
     *
     *
     *
     * @param type
     * @memberof Address
     */
    setType(type: AddressType): void {
        this.type = (typeof type === 'string' ? type.toUpperCase() : type) as AddressType;
    }

    /**
     * Returns full name of current address in format: Title FirsName LastName
     *
     * @readonly
     * @memberof Address
     */
}
