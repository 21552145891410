import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../core/interfaces/state.interface';
import { NotificationsState, NOTIFICATIONS_STATE_KEY, notificationsSelectors } from './notifications.reducer';

/** Try to keep naming convention and group as examples below */
export const selectNotificationsState = createFeatureSelector<NotificationsState>(NOTIFICATIONS_STATE_KEY);
export const selectNotifications = createSelector(selectNotificationsState, notificationsSelectors.selectAll);
export const selectNotificationsSorted = createSelector(selectNotifications, notifications =>
  notifications.sort((a, b) => new Date(b.data.createdAt).valueOf() - new Date(a.data.createdAt).valueOf()));
export const selectNotificationsCount = createSelector(selectNotificationsState, notificationsSelectors.selectTotal);
