import { Component, OnInit, OnDestroy } from '@angular/core';
import { BannerRef } from '../../../banner/banner-ref';

@Component({
  selector: 'app-bad-connection-banner',
  templateUrl: './bad-connection-banner.component.html',
  styleUrls: ['./bad-connection-banner.component.scss']
})
export class BadConnectionBannerComponent implements OnInit {

  constructor(
    public readonly bannerRef: BannerRef<BadConnectionBannerComponent>,
  ) { }

  ngOnInit(): void {}

  close() {
    this.bannerRef.close();
  }

}
