import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AddressType, IAddress } from '@sirius/common';
import { Address } from '../../../core/models/address.model';
import { Helper } from '../../../core/utils/helper';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  /** Creating null address if no address provided */
  @Input() address: IAddress = new Address();
  /** Address types to be displayed in dropdown */
  @Input() addressTypes: AddressType[] = [];
  /** Sets form mode editable or readonly */
  @Input() editable$ = new Observable<boolean>();
  /** Indicates if form already processing */
  @Input() busy$ = new Observable<boolean>();
  addressForm: UntypedFormGroup;
  isEditable = false;
  private readonly _subs = new Subscription();

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) { }

  get form(): UntypedFormGroup {
    return this.addressForm;
  }

  ngOnInit() {
    this.reset();
    this._handleEditableChange();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  /**
   * Reset form to initial state
   *
   * @memberof AddressFormComponent
   */
  reset() {
    this._initForm();
    this.addressForm.markAllAsTouched();
  }

  /**
   * Returns current form group
   *
   * @readonly
   * @type {FormGroup}
   * @memberof AddressFormComponent
   */

  private _handleEditableChange() {
    this._subs.add(this.editable$.subscribe(isEditable => {
      this.isEditable = isEditable;
      if (this.isEditable) {
        this.addressForm.enable();
      } else {
        this.addressForm.disable();
      }
    }));
  }

  private _initForm() {
    const address = this.address;
    this.addressForm = this.fb.group({
      type: [address.type, [Validators.required]],
      title: [address.title, [Validators.maxLength(10)]],
      firstName: [address.firstName, [Validators.maxLength(40)]],
      lastName: [address.lastName, [Validators.maxLength(40), Validators.required]],
      companyName: [address.companyName, [Validators.maxLength(60)]],
      addressLine1: [address.addressLine1, [Validators.maxLength(40)]],
      addressLine2: [address.addressLine2, [Validators.maxLength(40)]],
      addressLine3: [address.addressLine3, [Validators.maxLength(40)]],
      town: [address.town, [Validators.maxLength(40)]],
      country: [address.country, [Validators.maxLength(40)]],
      postcode: [address.postcode, [Validators.maxLength(10)]],
      telephone1: [address.telephone1, [Validators.maxLength(20), Validators.pattern(Helper.PHONE_NO_PATTERN)]],
      telephone2: [address.telephone2, [Validators.maxLength(20), Validators.pattern(Helper.PHONE_NO_PATTERN)]],
      fax: [address.fax, [Validators.maxLength(20), Validators.pattern(Helper.PHONE_NO_PATTERN)]],
      mobile: [address.mobile, [Validators.maxLength(20), Validators.pattern(Helper.PHONE_NO_PATTERN)]],
      email: [address.email, [Validators.maxLength(50), Validators.pattern(Helper.EMAIL_PATTERN)]],
    });
  }
}
