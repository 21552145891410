import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { CommunicationData } from '../interfaces/communication-data.interface';
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsSocketService {
  notificationReceived$ = this.socket.fromEvent<CommunicationData>('COMMUNICATION_RECEIVED').pipe(
    map(comm => new Notification('COMMUNICATION', comm))
  );

  notificationRevealed$ = this.socket.fromEvent<CommunicationData>('COMMUNICATION_REVEALED');

  constructor(private readonly socket: Socket) {}
}
