// eslint-disable-next-line max-len
import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, OnDestroy, StaticProvider, Type } from '@angular/core';
import { BannerContainerComponent } from './banner-container.component';
import { BannerRef } from './banner-ref';
import { BannerConfig } from './interfaces/banner-config.interface';

@Injectable({
  providedIn: 'root'
})
export class BannerService implements OnDestroy {
  bannerRefs: BannerRef<any>[] = [];

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
  ) { }

  ngOnDestroy() {
    this._closeAllBanners();
  }

  /**
   * Create new banner from given component and attach banner to body.
   *
   * @param component
   * @param config
   */
  open<T>(component: Type<T>, config?: BannerConfig): BannerRef<T> {
    const bannerComponentRef = this._createBannerContainer<T>(config);
    const bannerRef = new BannerRef<T>(bannerComponentRef);
    const injector = this._createInjector(bannerRef);
    const componentRef = bannerComponentRef.instance.attachBody(component, bannerRef, injector);
    bannerRef.componentInstance = componentRef.instance;
    bannerRef.afterClose.subscribe(() => this._removeBannerOpenBanner(bannerRef));
    this.bannerRefs.push(bannerRef);

    if (config && config.timeout) {
      setTimeout(() => {
        bannerRef.close();
      }, config.timeout);
    }

    return bannerRef;
  }

  /**
   * Closes all opened banners
   */
  private _closeAllBanners(): void {
    this.bannerRefs.forEach(banner => banner.close());
  }

  /**
   * Removes open banner from DOM tree
   *
   * @private
   * @template T T
   * @param bannerRef
   * @memberof BannerService
   */
  private _removeBannerOpenBanner<T>(bannerRef: BannerRef<T>): void {
    const bannerIndex = this.bannerRefs.indexOf(bannerRef);
    this.appRef.detachView(bannerRef.parentContainerRef.hostView);
    bannerRef.parentContainerRef.destroy();
    this.bannerRefs.splice(bannerIndex, 1);
  }

  /**
   * Creates BannerContainer component and appends it to document.body
   *
   * @private
   * @template T T
   * @param config
   * @returns
   * @memberof BannerService
   */
  private _createBannerContainer<T>(config: BannerConfig) {
    const bannerComponentFactory = this.componentFactoryResolver.resolveComponentFactory(BannerContainerComponent);
    const bannerComponentRef = bannerComponentFactory.create(this.injector);
    this.appRef.attachView(bannerComponentRef.hostView);
    const domElem = (bannerComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    domElem.classList.add('mat-elevation-z2');
    domElem.classList.add('app-banner');

    if (config && config.target) {
      config.target.nativeElement.appendChild(domElem);
    } else {
      document.body.appendChild(domElem);
    }
    return bannerComponentRef;
  }

  /**
   * Creates new injector with BannerRef to be injected in to banner container.
   *
   * @private
   * @template T T
   * @param bannerRef
   * @returns
   * @memberof BannerService
   */
  private _createInjector<T>(bannerRef: BannerRef<T>, userInjector: Injector = this.injector): Injector {
    const providers: StaticProvider[] = [
      { provide: BannerRef, useValue: bannerRef }
    ];
    return Injector.create({ parent: userInjector, providers });
  }

}
