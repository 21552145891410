/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { addComm } from '../../communications/store/communications.actions';
import { Communication } from '../../core/models/communication.model';
import { Job } from '../../jobs/models/job.model';
import { addJobCommunicationSuccess } from '../../jobs/store/job.actions';
import { NotificationsSocketService } from '../services/notifications-socket.service';
import { NotificationsService } from '../services/notifications.service';
import * as NotificationsActions from './notifications.actions';

@Injectable()
export class NotificationsEffects {

  liveCreate$ = createEffect(() => this.notificationsSocketService.notificationReceived$.pipe(
    mergeMap(notification => {
      const communication = new Communication({
        createdAt: moment(notification.data.createdAt),
        createdBy: notification.data.createdBy,
        read: false,
        query: undefined,
        type: notification.data.type,
        _id: notification.data.RowKey,
        siteCode: notification.data.PartitionKey,
      }).serialize() as Communication;

      return [
        NotificationsActions.createNotification({ notification }),
        addComm({ communication, jobId: notification.data.jobId }),
        addJobCommunicationSuccess({ jobId: notification.data.jobId, communication }),
      ];
    })
  ));


  liveReveal$ = createEffect(() => this.notificationsSocketService.notificationRevealed$.pipe(
    map(_ => NotificationsActions.loadNotifications()),
  ));

  loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationsActions.loadNotifications),
    mergeMap(_ => this.notificationsService.getNotifications().pipe(
        map(notifications => NotificationsActions.loadNotificationsSuccess({ notifications })),
        catchError((e) => {
          this.snack.open(`Unable to load notices.`);
          return of(NotificationsActions.loadNotificationsError({ error: e }));
        }),
      )),
  ));

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly notificationsSocketService: NotificationsSocketService,
    private readonly actions$: Actions,
    private readonly snack: MatSnackBar,
  ) { }

}
