import { ComponentRef, EventEmitter } from '@angular/core';
import { BannerContainerComponent } from './banner-container.component';

// eslint-disable-next-line no-shadow
export enum BannerRefState {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLOSED,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OPEN,
}

/**
 * Banner Component Reference
 *
 * @export
 * @class BannerRef
 * @template T
 */
export class BannerRef<T> {
  /** Fired just after banner is opened */
  afterOpen = new EventEmitter<void>();
  /** Fired after banner close */
  afterClose = new EventEmitter<void>();
  /** Instance of banner container body component */
  componentInstance: T;
  private _state = BannerRefState.OPEN;

  constructor(
    private readonly _parentContainerRef: ComponentRef<BannerContainerComponent>,
  ) {
  }

  get parentContainerRef(): ComponentRef<BannerContainerComponent> {
    return this._parentContainerRef;
  }

  get state(): BannerRefState {
    return this._state;
  }

  /**
   * Closes current banner, emits event after close
   *
   * @memberof BannerRef
   */
  close(): void {
    this._state = BannerRefState.CLOSED;
    this.afterClose.emit();
  }

}
