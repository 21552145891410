import { Component, Input, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from '../../../core/utils/helper';
import { CommunicationData } from '../../interfaces/communication-data.interface';
import { Notification } from '../../models/notification.model';
@Component({
  selector: 'app-communication-notification-item',
  templateUrl: './communication-notification-item.component.html',
  styleUrls: ['./communication-notification-item.component.scss']
})
export class CommunicationNotificationItemComponent implements OnInit {
  @Input() item: Notification<CommunicationData>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DATE_TIME_FORMAT = Helper.DATE_TIME_FORMAT;
  providerIconStyle$: Promise<SafeStyle>;
  hasProviderIcon: boolean;

  constructor(
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.providerIconStyle$ = this.getProviderIconStyle();
  }

  /**
   * Creates styles for provider icon
   *
   * @readonly
   * @memberof OfferCardComponent
   */
  async getProviderIconStyle(): Promise<string> {
    const { data } = this.item;
    if (data?.workProviderName) {
      const img = await Helper.loadImage(`../../../assets/images/providers/${this.item.data.workProviderName}.png`);
      this.hasProviderIcon = true;
      return `
      background-image: url(${img});
      background-size: contain;
      background-repeat: no-repeat;
      background-position:center center;
      `;
    } else {
      this.hasProviderIcon = false;
      return;
    }
  }

  /**
   * Navigates to job communications and mark notification as seen
   *
   * @memberof CommunicationNotificationItemComponent
   */
  onClick(e: Event): void {
    this._navigateToJobCommunications(this.item.data.jobId);
  }

  private _navigateToJobCommunications(jobId: string) {
    this.router.navigate(['jobs', jobId, 'communications']);
  }

}
