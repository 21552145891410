/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { Model } from './abstract.model';
import { ICommunication } from '@sirius/common';
import moment from 'moment';
import { Moment } from 'moment';
import { Helper } from '../utils/helper';

export class Communication extends Model<ICommunication> implements ICommunication {
  createdBy: string;
  createdAt: Moment;
  type: string;
  query: string;
  response: string;
  icon = '';
  /** Default communication read state is true */
  read = true;
  siteCode: string;
  jobId?: string;

  /**
   * Creates an instance of Communication.
   *
   * @param {ICommunication} config
   * @param {string} [siteCode]
   * @memberof Communication
   */
  constructor(config: ICommunication);
  /**
   * @deprecated please pass siteCode inside ICommunication config instead
   */
  constructor(config: ICommunication, siteCode: string);
  constructor(config?: any, siteCode?: any) {
    super(config);
    this.icon = this._getIconByType();
    this.createdAt = moment(config.createdAt);

    if (!config._id) {
      this._id = Helper.mongoDbId();
    }

    if (config.read === false) {
      this.read = config.read;
    }

    if (config.query) {
      this.query = this.htmlSpecialChars(config.query);
    }

    if (siteCode) {
      this.siteCode = siteCode;
    }
  }

  public htmlSpecialChars(input): any {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };

    return input.replace(/[&<>"']/g, (m) => map[m]);
  }

  private _getIconByType?(type: string = this.type): string {
    if (!type) {
      return 'comments';
    }

    switch (type.toLocaleLowerCase()) {
      case 'repex incoming msg': return 'comment-alt';
      case 'email': return 'envelope';
      case 'internal': return 'location-arrow';
      case 'fax': return 'fax';
      case 'sms': return 'sms';
      case 'update': return 'sync';
      case 'telephone': return 'phone';
      case 'letter': return 'envelope-open-text';
      case 'ecd change': return 'cogs';
      case 'cancelled': return 'ban';
      case 'communication': return 'comment-alt';
      default: return 'comments';
    }
  }
}
