import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent implements OnInit {
  readonly logoUrl = 'https://autoflowfrontend.blob.core.windows.net/assets/autoflow-log.svg';
  public siteCodes: any;
  public siteNames: any;
  constructor(protected keycloakAngular: KeycloakService) { }

  ngOnInit() {
    this.siteCodes = this.getSiteCodes();
  }

  getSiteCodes(): string[] {
    const token = (this.keycloakAngular.getKeycloakInstance().idTokenParsed as KeycloakTokenParsed & { siteCodes: string[] });
    if (token.siteCodes) {
      return token.siteCodes;
    } else {
      console.log('Failed to load site code.');
      return [];
    }
  }
}
