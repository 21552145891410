import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function keycloakInitializer(keycloak: KeycloakService): () => Promise<void> {
  return async (): Promise<void> => {
    await keycloak.init({
      config: environment.keycloak,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: []
    });
  };
}
