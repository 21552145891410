import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SaveChangesDialogData {
  saveActionRef: () => void;
}

@Component({
  selector: 'app-save-changes-dialog',
  templateUrl: './save-changes-dialog.component.html',
  styleUrls: ['./save-changes-dialog.component.scss']
})
export class SaveChangesDialogComponent implements OnInit {
  title = 'Want to save your changes?';

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: SaveChangesDialogData,
    private readonly dialogRef: MatDialogRef<SaveChangesDialogComponent>
  ) { }

  ngOnInit(): void {
    if (typeof this.data.saveActionRef !== 'function') {
      throw new Error('Looks like save action reference you have passed is not a function.');
    }
  }

  /**
   * Calls save action ref and closes dialog
   *
   * @memberof SaveChangesDialogComponent
   */
  save() {
    this.data.saveActionRef();
    this.dialogRef.close(true);
  }

  /**
   * Close dialog with decision
   *
   * @param force will force result to true without calling save, used for [discard changes/ dont save] behavior
   * @memberof ConfirmationDialogComponent
   */
  close(force: boolean) {
    this.dialogRef.close(force);
  }

}
