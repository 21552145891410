/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAddress, IJob, JsendResponse } from '@sirius/common';
import { Communication } from '../../core/models/communication.model';
import { UrlService } from '../../core/services/url.service';
import { Job } from '../models/job.model';
import { IServerSideGetRowsRequest } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlService: UrlService,
  ) { }

  /**
   * Load Job from Job Service by passed id
   *
   * @returns
   * @memberof OfferService
   */
  getJob(id: string): Observable<Job> {
    return this.httpClient.get(this.urlService.getUrl('JOB', { ':id': id })).pipe(
      map((response: JsendResponse<Job>) => new Job(response.data).serialize() as Job)
    );
  }

  /**
   * Returns list of jobs from server, params should define query params
   *
   * @returns
   * @memberof OfferService
   */
  getJobs(params: IServerSideGetRowsRequest & { searchValue?: string }): Observable<{ jobs: Job[]; last: number }> {
    return this.httpClient.post(this.urlService.getUrl('JOBS_SEARCH'), params).pipe(
      map((response: JsendResponse<{ jobs: Job[]; last: number }>) =>
        ({ jobs: response.data.jobs.map((item) => new Job(item)), last: response.data.last })),
    );
  }

  /**
   * Matches address by id and set man contact to given value, also sets current main contact to false
   *
   * @param jobId
   * @param addressId
   * @returns
   * @memberof JobService
   */
  setMainContact(jobId: string, addressId: string, isMainContact: boolean, siteCode: string): Observable<void> {
    return this.httpClient.patch<void>(
      this.urlService.getUrl('JOB_UPDATE_MAIN_CONTACT', { ':id': jobId }),
      { isMainContact, siteCode, addressId }
    );
  }

  /**
   * Send update details to JobService
   *
   * @param id
   * @param changes
   * @returns
   * @memberof JobService
   */
  updateJob(id: string, changes: Partial<IJob>): Observable<HttpResponse<void>> {
    return this.httpClient.patch<HttpResponse<void>>(
      this.urlService.getUrl('JOB', { ':id': id }),
      changes,
    );
  }

  /**
   * Sends update address details to JobService
   *
   * @param id
   * @param changes
   * @returns
   * @memberof JobService
   */
  updateJobAddress(jobId: string, addressId: string, changes: Partial<IAddress>): Observable<HttpResponse<void>> {
    return this.httpClient.patch<HttpResponse<void>>(
      this.urlService.getUrl('JOB_ADDRESS', { ':id': jobId, ':addressId': addressId }),
      changes,
    );
  }

  /**
   * Sends delete job address request
   *
   * @param id
   * @param addressId
   * @returns
   * @memberof JobService
   */
  deleteJobAddress(jobId: string, addressId: string): Observable<HttpResponse<void>> {
    return this.httpClient.delete<HttpResponse<void>>(
      this.urlService.getUrl('JOB_ADDRESS', { ':id': jobId, ':addressId': addressId }),
    );
  }

  /**
   * Sends create address request
   *
   * @param id
   * @param address
   * @returns
   * @memberof JobService
   */
  addJobAddress(jobId: string, address: IAddress): Observable<string> {
    return this.httpClient.post(this.urlService.getUrl('JOB_ADDRESSES', { ':id': jobId }), address).pipe(
      map((response: JsendResponse<string>) => response.data),
    );
  }

  /**
   * Add new comm to existing job
   *
   * @param id
   * @param comms
   * @returns
   * @memberof JobService
   */
  addComm(id: string, communication: Communication): Observable<Communication> {
    return this.httpClient.post(
      this.urlService.getUrl('JOB_COMMUNICATIONS_ADD', { ':id': id }),
      communication,
    ).pipe(
      map((response: JsendResponse<Communication>) => response.data),
    );
  }

  /**
   * Returns grouped filter values of all existing jobs per site
   *
   * @param field
   * @returns
   * @memberof JobService
   */
  getJobFilterValues(fields: string[] = [
    'site.shortName',
    'workProvider.name',
    'vehicleDetails.colour',
    'vehicleDetails.make',
    'state',
    'policyType',
  ]): Observable<{ [key: string]: string[] }> {
    return this.httpClient.get(this.urlService.getUrl('JOB_FILTER_VALUES'), { params: { fields } }).pipe(
      map((response: JsendResponse<{ [key: string]: string[] }>) => response.data),
    );
  }

}
