// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KeycloakConfig } from 'keycloak-js';
import { SiriusEnvironment } from '../app/core/interfaces/sirius-environment.interface';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
  url: 'https://login.testing.sirius.autoflow.ltd.uk/',
  realm: 'testing',
  clientId: 'sirius-web',
};

export const environment: SiriusEnvironment = {
  production: false,
  apiUrl: '/api',
  assetsUrl: 'https://autoflowfrontend.blob.core.windows.net/assets',
  socketUrl: '',
  keycloak: keycloakConfig,
  requestTimeout: 30 * 1000,
  requestRetry: 3,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
