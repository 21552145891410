import {
  CancelledReasons,
  DiaryEventType,
  ECDChangeReasons,
  IAddress,
  ICommunication,
  IEstimateDetails,
  IHeadInvoice,
  IImage,
  IJob,
  IJobEvents,
  IJobType,
  INote,
  ISiteDetails,
  IVehicleDetail,
  IWorkProvider,
  IWorkshopTask,
  JobEventsNull,
  JobEventType,
  JobStateType,
  PolicyType,
  ScheduledEvent,
  SourceSystem,
  TransmissionType
} from '@sirius/common';
import * as Keycloak from 'keycloak-js';
import moment from 'moment';
import { ISite } from '../../core/interfaces/site.interface';
import { Model } from '../../core/models/abstract.model';
import { Address } from '../../core/models/address.model';
import { Communication } from '../../core/models/communication.model';
import { Image } from '../../core/models/image.model';
import { Helper } from '../../core/utils/helper';

export interface DiaryEventObj {
  appointment: ScheduledEvent<DiaryEventType>;
  collection: ScheduledEvent<DiaryEventType>;
  delivery: ScheduledEvent<DiaryEventType>;
  inspection: ScheduledEvent<DiaryEventType>;
  recovery: ScheduledEvent<DiaryEventType>;
}

/**
 * Job model, model can be used only for initialization (e.g. after you get data from server)
 * as NGRX Store sets object prototype to object
 *
 * @export
 * @class Job
 * @extends {Model<IJob>}
 * @implements {IJob}
 */
export class Job extends Model<IJob> implements IJob {
  driveable?: boolean;
  _id: string;
  state: JobStateType;
  offerId: number;
  siteCode: string;
  site: ISite;
  jobReference: string;
  groupId: number;
  workProvider: IWorkProvider;
  policyType: PolicyType;
  policyNumber: string;
  claimNumber: string;
  contributionAmount: number;
  excessAmount: number;
  vatLiability: number;
  vehicleDetails: IVehicleDetail;
  estimateDetails: IEstimateDetails;
  jobEvents: IJobEvents;
  jobType?: IJobType;
  rejectedReason?: string;
  thirdPartyClaim?: boolean;
  nonFaultClaim?: boolean;
  incidentDate?: moment.Moment;
  mobileEstimateRequired?: boolean;
  recoverFromThirdParty?: boolean;
  collectionRequired?: boolean;
  deliveryRequired?: boolean;
  recoveryAgentCode?: string;
  replacementVehicleRequired?: boolean;
  replacementVehicleArranged?: boolean;
  replacementVehicleType?: string;
  replacementVehicleReg?: string;
  notes?: INote[];
  addresses?: Address[];
  communications?: Communication[];
  workShopTasks?: IWorkshopTask[];
  distFromIncident: number;
  distFromInsured: number;
  insuredPostcode: string;
  incidentPostcode: string;
  diaryEvents?: ScheduledEvent<DiaryEventType>[];
  /** Used only for form edit propose */
  diaryEventsObj?: DiaryEventObj;
  siteDetails: ISiteDetails;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  images: IImage[];
  invoices: IHeadInvoice;
  cancelledReason: CancelledReasons;
  cancelledReasonDetails: string;
  cancelledReasonChange: boolean;
  expectedCompletionReason: ECDChangeReasons;
  expectedCompletionDetails: string;
  expectedCompletionChange: boolean;

  constructor(config?: IJob) {
    super(config);

    if (!config) {
      return;
    }

    this.site = (config as IJob & { site: ISite }).site || {
      name: '',
      shortName: '',
      code: config.siteCode,
    };
    this.createdAt = moment(config.createdAt);
    this.updatedAt = moment(config.updatedAt);
    this.incidentDate = moment(config.incidentDate).format('YYYY') === '0000' ? null : moment(config.incidentDate);
    this.workProvider = { ...config.workProvider };
    this.invoices = this.mapInvoices(config.invoices);
    this.estimateDetails = this.mapEstimateDetails(config.estimateDetails);
    this.invoices.invoices = []; // Temporary until invoices will be separated so sections
    this.vehicleDetails = this.mapVehicleDetails(config.vehicleDetails);
    this.jobEvents = this.mapJobEvents(config.jobEvents);
    this.diaryEvents = this.mapDiaryEvents(config.diaryEvents);
    this.addresses = this.mapAddresses(config.addresses);
    this.images = this.mapImages(config.images);
    this.communications = this.mapCommunications(config.communications);
    this.diaryEventsObj = this._createDiaryEvents(this.diaryEvents);
  }

  /** Static constructor for loop methods
   *
   * @example
   * jobsArray = serializedJobArray.map(Job.Job)
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static Job(data: Job): Job {
    return new Job(data);
  }

  mapInvoices(invoices: IHeadInvoice = {} as IHeadInvoice): IHeadInvoice {
    return {
      totalLabourNet: invoices.totalLabourNet || 0,
      totalPartsNet: invoices.totalPartsNet || 0,
      totalMaterialsNet: invoices.totalMaterialsNet || 0,
      totalOtherNet: invoices.totalOtherNet || 0,
      totalNet: invoices.totalNet || 0,
      totalTax: invoices.totalTax || 0,
      invoices: invoices.invoices || [],
    };
  }

  mapEstimateDetails(estimateDetails: IEstimateDetails = {} as IEstimateDetails): IEstimateDetails {
    return {
      estimator: estimateDetails.estimator || 'N/A',
      sourceSystem: estimateDetails.sourceSystem || SourceSystem.NT,
      partsLabourRatio: estimateDetails.partsLabourRatio || 0,
      replaceRatio: estimateDetails.replaceRatio || 0,
      totalLabourHours: estimateDetails.totalLabourHours || 0,
      totalLabourNet: estimateDetails.totalLabourNet || 0,
      totalMaterialsNet: estimateDetails.totalMaterialsNet || 0,
      totalNet: estimateDetails.totalNet || 0,
      totalOtherNet: (estimateDetails.totalOtherNet || 0) + (estimateDetails.totalSpecialistNet || 0),
      totalPartsNet: estimateDetails.totalPartsNet || 0,
      totalSpecialistNet: 0,
      labourRate: estimateDetails.labourRate || 0,
    };
  }

  mapVehicleDetails(vehicleDetails: IVehicleDetail): IVehicleDetail {
    const vehicleDetailsMapped = { ...vehicleDetails };
    vehicleDetailsMapped.transmissionType = vehicleDetailsMapped.transmissionType || TransmissionType.UNKNOWN;
    vehicleDetailsMapped.serviceDueDate = moment(vehicleDetailsMapped.serviceDueDate);
    vehicleDetailsMapped.MOTDate = moment(vehicleDetailsMapped.MOTDate);
    return vehicleDetailsMapped;
  }

  mapAddresses(addresses: IAddress[] = []): Address[] {
    return addresses.map(address => new Address(address));
  }

  mapImages(images: IImage[] = []): Image[] {
    return images.map(image => new Image(image));
  }

  mapCommunications(communications: ICommunication[] = []): Communication[] {
    return communications.map(com => new Communication({ ...com, siteCode: this.siteCode }));
  }

  /**
   *
   *
   * Map diary events in to DOs
   *
   *
   *
   * @param [diaryEvents=[]]
   * @returns
   * @memberof Job
   */
  mapDiaryEvents(diaryEvents: ScheduledEvent<DiaryEventType>[] = []): ScheduledEvent<DiaryEventType>[] {
    return diaryEvents
      .map(({ type, scheduledOn, createdAt }) => ({ type, createdAt: moment(createdAt), scheduledOn: moment(scheduledOn) }));
  }

  /**
   * Map job events to DOs
   *
   * @returns
   * @memberof Job
   */
  mapJobEvents(jobEvents: IJobEvents = new JobEventsNull()): IJobEvents {
    return Object.keys(jobEvents).reduce((acc, key) => {
      if (jobEvents[key].createdAt) {
        acc[key] = { type: jobEvents[key].type, createdAt: moment(jobEvents[key].createdAt) };
      }
      return acc;
    }, new JobEventsNull());
  }

  /**
   * Pre process model in to update friendly version (used for updating job details form)
   *
   * @memberof Job
   */
  preUpdateProcess({ firstName, lastName }: Keycloak.KeycloakProfile): void {
    this.jobEvents.firstContacted = {
      createdAt: Helper.momentOrNull(this.jobEvents.firstContacted.createdAt),
      type: JobEventType.FIRST_CONTACTED
    };

    this.jobEvents.notification = {
      createdAt: Helper.momentOrNull(this.jobEvents.notification.createdAt),
      type: JobEventType.NOTIFICATION
    };

    this.jobEvents.originalExpectedCompletion = {
      createdAt: Helper.momentOrNull(this.jobEvents.originalExpectedCompletion.createdAt),
      type: JobEventType.ORIGINAL_EXPECTED_COMPLETION
    };

    this.estimateDetails.totalLabourHours = +this.estimateDetails.totalLabourHours || 0;

    this.diaryEvents = Object.keys(this.diaryEventsObj).reduce((acc, key) => {
      const event = this.diaryEventsObj[key];
      if (event.type && event.scheduledOn !== null) {
        acc.push({
          type: event.type,
          createdAt: event.createdAt,
          scheduledOn: event.scheduledOn
        });
      }
      return acc;
    }, []);

    if (this.expectedCompletionChange) {
      this.communications.push(new Communication({
        createdAt: moment(),
        createdBy: `${firstName} ${lastName}`,
        query: `${this.expectedCompletionReason} - ${this.expectedCompletionDetails}`,
        read: true,
        type: 'ECD CHANGE',
        siteCode: this.siteCode,
      }));
    }

    if (this.cancelledReasonChange) {
      this.communications.push(new Communication({
        createdAt: moment(),
        createdBy: `${firstName} ${lastName}`,
        query: `${this.cancelledReason} - ${this.cancelledReasonDetails}`,
        read: true,
        type: 'CANCELLED',
        siteCode: this.siteCode,
      }));
    }
  }

  /**
   * TODO: After Sirius 2 we will need to move this to calendar, this will no longer exists.
   */
  private _createDiaryEvents(diaryEvents: ScheduledEvent<DiaryEventType>[]): DiaryEventObj {
    return {
      appointment: (diaryEvents.find(item => item.type === 'APPOINTMENT' && item.scheduledOn) ||
        { scheduledOn: null, type: DiaryEventType.APPOINTMENT, createdAt: null }),
      collection: (diaryEvents.find(item => item.type === 'COLLECTION' && item.scheduledOn) ||
        { scheduledOn: null, type: DiaryEventType.COLLECTION, createdAt: null }),
      delivery: (diaryEvents.find(item => item.type === 'DELIVERY' && item.scheduledOn) ||
        { scheduledOn: null, type: DiaryEventType.DELIVERY, createdAt: null }),
      inspection: (diaryEvents.find(item => item.type === 'INSPECTION' && item.scheduledOn) ||
        { scheduledOn: null, type: DiaryEventType.INSPECTION, createdAt: null }),
      recovery: (diaryEvents.find(item => item.type === 'RECOVERY' && item.scheduledOn) ||
        { scheduledOn: null, type: DiaryEventType.RECOVERY, createdAt: null }),
    };
  }

}
