import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserIdleService } from 'angular-user-idle';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { filter } from 'rxjs/operators';
import { SessionExpiryDialogComponent } from './core/components/session-expiry-dialog/session-expiry-dialog.component';
import { AppState } from './core/interfaces/state.interface';
import { WebsocketAuthService } from './core/services/websocket-auth.service';
import { loadJobStats, detectDevice, setGroupUser, setUserProfile } from './core/store/core.actions';
import * as appInfo from '../../version';
import { CoreService } from './core/services/core.service';

declare let gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  title = 'AutoFlow Sirius';
  userDetails: KeycloakProfile;
  idleDialog: MatDialogRef<SessionExpiryDialogComponent>;
  locale: string;

  private readonly userIdleSettings = {
    idle: 15 * 60,
    timeout: 2 * 60,
    ping: 5,
  };

  constructor(
    private readonly coreService: CoreService,
    private readonly keycloakService: KeycloakService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
    private readonly userIdle: UserIdleService,
    private readonly store: Store,
    private readonly webSocketsAuthService: WebsocketAuthService,
  ) { }

    @HostListener('window:resize', ['$event'])
    public onResize() {
      this.store.dispatch(detectDevice());
    }

  ngOnInit() {
    this._setupTranslations();
    this._reportPageViews();
    this._handleUserIdle();
    this._sessionExpiryDialog();
    this._initializeUser();
    this._handleLocalStorageVersioning();
    this.store.dispatch(loadJobStats());
  }

  reportPageView(e: NavigationEnd) {
    gtag('config', 'UA-152652919-1', { pagePath: e.urlAfterRedirects }, { optimizeId: 'GTM-PM5KLGR' });
  }


  /**
   * Initialize authenticated user.
   *
   * @private
   * @memberof AppComponent
   */
  private async _initializeUser() {
    if (await this.keycloakService.isLoggedIn()) {
      await this._setTokenCookie();

      this.userDetails = await this.keycloakService.loadUserProfile();

      if ((this.keycloakService.getKeycloakInstance().tokenParsed as any).siteCodes.length > 1) { this.store.dispatch(setGroupUser()); }

      this.store.dispatch(setUserProfile({ userProfile: this.userDetails }));

      this.webSocketsAuthService.authenticate(await this.keycloakService.getToken());
    } else {
      console.error(`User is not logged in.`);
    }
  }

  private async _setTokenCookie() {
    window.document.cookie = `token=${await this.keycloakService.getToken()}`;
  }

  /** TODO: Handle minor and major version change (START VERSIONING CORRECTLY) */
  private _handleLocalStorageVersioning() {
    const storageVersionKey = 'storage_version';
    const storageVersion = localStorage.getItem(storageVersionKey);
    if (!storageVersion || storageVersion !== appInfo.version) {
      localStorage.clear();
      localStorage.setItem(storageVersionKey, appInfo.version);
    }
  }

  private _setupTranslations() {
    this.translate.setDefaultLang(this.coreService.getUserLocale());
  }

  private _reportPageViews() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.reportPageView(e);
    });
  }

  private _handleUserIdle() {
    this.userIdle.setConfigValues(this.userIdleSettings);

    this.userIdle.startWatching();
  }

  /**
   * Call this method on application init to let app show session expiry dialog
   *
   * @private
   * @memberof AppComponent
   */
  private _sessionExpiryDialog() {
    this.userIdle.onTimerStart().subscribe(counter => {
      if (this.idleDialog) {
        return;
      }
      this.idleDialog = this.dialog.open(SessionExpiryDialogComponent, {
        disableClose: true,
        closeOnNavigation: false,
        hasBackdrop: true,
        data: this.userIdleSettings,
      });
      this.idleDialog.afterClosed().subscribe(async (refreshSession: boolean) => {
        if (refreshSession) {
          this.keycloakService.updateToken(15 * 60);
          this._setTokenCookie();
          this.userIdle.resetTimer();
          this.idleDialog = null;
        }
      });
    });

    this.userIdle.onTimeout().subscribe(() => {
      this.keycloakService.logout();
    });
  }
}
