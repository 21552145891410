import { createReducer, on } from '@ngrx/store';
import { IJob } from '@sirius/common';
import { addRecentJob } from './recent.actions';

export const RECENT_STATE_KEY = 'recent';

export interface RecentState {
  recentJobsList: {
    viewed: Date;
    job: IJob;
  }[];
}

export const initialState: RecentState = {
  recentJobsList: [],
};

export const _recentReducer = createReducer(initialState,
  on(addRecentJob, (state: RecentState, action) => {
    let recentJobsList = state.recentJobsList.filter(item => item.job._id !== action.job._id);
    recentJobsList.unshift(action);
    recentJobsList = recentJobsList.slice(0, 6);
    return { ...state, recentJobsList };
  }),
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function recentReducer(state, action) {
  return _recentReducer(state, action);
}
