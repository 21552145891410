
export type NotificationType = 'COMMUNICATION';

/**
 * Notification to be displayed on notification dropdown
 *
 * @export
 * @class Notification
 * @template T Data type, for example 'Communication',
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class Notification<T extends { RowKey: string } = any> {
  /** MongoId */
  _id: string;
  constructor(public type: NotificationType, public data: T) {
    this._id = data.RowKey;
  }
}
