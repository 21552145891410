<app-grid-layout>
    <mat-card appearance="outlined" class="address-card" [class.address-card--main]="address.isMainContact" *ngFor="let address of addresses">
        <mat-card-content>
            <mat-list>
                <mat-list-item class="address-card__title">
                    <h3 mat-line>{{address.type | titlecase}}</h3>
                    <button  *ngIf="!readonly" class="address-card__main-contact-toggle" mat-icon-button color="primary" matTooltip="{{address.isMainContact ? ('address-card__main-contact-toggle.tooltipReadOnly' | translate) : ('address-card__main-contact-toggle.tooltip' | translate)}}" (click)="toggleMainContact(address)">
                        <fa-icon *ngIf="!address.isMainContact && !isBusy" [icon]="['far', 'star']"></fa-icon>
                        <fa-icon *ngIf="address.isMainContact && !isBusy" [icon]="['fas', 'star']"></fa-icon>
                        <fa-icon *ngIf="isBusy" [icon]="['fas', 'spinner']" class="fa-spin fas"></fa-icon>
                    </button>
                    <div *ngIf="readonly">
                        <fa-icon *ngIf="!address.isMainContact" [icon]="['far', 'star']"></fa-icon>
                        <fa-icon *ngIf="address.isMainContact" [icon]="['fas', 'star']" matTooltip="{{'address-card__main-contact-toggle.tooltipReadOnly' | translate}}"></fa-icon>
                    </div>
                </mat-list-item>
                <mat-list-item>
                    <h4 mat-line>{{address.firstName+ ' '+ address.lastName}}</h4>
                    <p mat-line *ngIf="address.addressLine1">{{address.addressLine1}}</p>
                    <p mat-line *ngIf="address.addressLine2">{{address.addressLine2}}</p>
                    <p mat-line *ngIf="address.addressLine3">{{address.addressLine3}}</p>
                    <p mat-line *ngIf="address.town">{{address.town}}</p>
                    <p mat-line *ngIf="address.country">{{address.country}}</p>
                    <p mat-line *ngIf="address.postcode"> {{address.postcode}}</p>
                    <p mat-line *ngIf="address.telephone1">tel:{{address.telephone1}}</p>
                    <p mat-line *ngIf="address.telephone2">tel:{{address.telephone2}}</p>
                    <p mat-line *ngIf="address.fax">fax:{{address.fax}}</p>
                    <p mat-line *ngIf="address.mobile">mob:{{address.mobile}}</p>
                    <p mat-line *ngIf="address.email">{{address.email}}</p>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
        <mat-card-footer>
            <mat-divider *ngIf="!readonly"></mat-divider>
            <mat-card-actions align="end" class="address-card__actions" *ngIf="!readonly">
                <button mat-button (click)="setAddressToEdit(true)" color="primary" routerLink="{{address._id}}">{{'address-card__actions.open' | translate }}</button>
            </mat-card-actions>
        </mat-card-footer>
    </mat-card>
</app-grid-layout>