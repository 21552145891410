<div class="recent-list" [class.recent-list--closed]="toggleRecentList">
  <h2 class="recent-list__header">{{'recent-list.title' | translate}}</h2>
  <ul class="recent-list__list" *ngIf="(recentJobs$ | async)?.length">
    <li class="recent-list__item" *ngFor="let recentJob of recentJobs$ | async" >
      <a [routerLink]="['/jobs/', recentJob.job._id,'details']">
        <div class="recent-item__last-viewed">{{'recent-list.last-viewed' | translate}}: {{recentJob.viewed | date:'medium'}}</div>
        <app-job-card [job]="recentJob.job"></app-job-card>
      </a>
    </li>
  </ul>
  <p *ngIf="(recentJobs$ | async)?.length <= 0" class="recent-list__no-history">{{'recent-list.no-history' | translate}}.</p>
</div>