import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ComponentFactoryResolver, ComponentRef, HostBinding, Injector, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { BannerRef } from './banner-ref';

@Component({
  selector: 'app-banner-container',
  template: `<ng-container #bannerBody></ng-container>`,
  styleUrls: ['./banner-container.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)', opacity: 1
      })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-5rem)' }),
        animate('0.3s 0.1s ease', style({
          transform: 'translateY(0)',
          opacity: 1,
        })),
      ]),
      transition(':leave', [
        animate('0.3s 0.1s ease', style({
          transform: 'translateY(-5rem)',
          opacity: 0,
        })),
      ])
    ]),
  ],
})
export class BannerContainerComponent {
  @HostBinding('@slideInOut') public animate = true;
  @ViewChild('bannerBody', { read: ViewContainerRef, static: true }) public bannerBody: ViewContainerRef;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  /**
   * Create new component and appends it to this container
   *
   * @template T T
   * @param component
   * @param bannerRef
   * @param injector
   * @returns
   * @memberof BannerContainerComponent
   */
  attachBody<T>(component: Type<T>, bannerRef: BannerRef<T>, injector: Injector): ComponentRef<T> {
    const bodyComponentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(component);
    const bodyComponentRef = this.bannerBody.createComponent(
      bodyComponentFactory,
      undefined,
      injector,
    );
    return bodyComponentRef;
  }
}
