import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../core/interfaces/state.interface';
import { selectRecentJobsList } from '../../store/recent.selectors';
import { IJob } from '@sirius/common';
import { selectIsGroupUser, selectIsMobile } from '../../../core/store/core.selectors';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.scss']
})

export class RecentComponent implements OnInit {
  @Input() toggleRecentList: boolean;
  recentJobs$: Observable<{viewed: Date; job: IJob}[]>;
  public isGroupUser$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  constructor(
    private readonly store: Store) { }

  ngOnInit(): void {
    this.recentJobs$ = this.store.select(selectRecentJobsList);
    this.isGroupUser$ = this.store.select(selectIsGroupUser);
    this.isMobile$ = this.store.select(selectIsMobile);
    this.isMobile$.subscribe((data) => {
      if (data) { this.toggleRecentList = true; }
    }).unsubscribe();
  }
  close() {
    this.toggleRecentList = !this.toggleRecentList;
  }
}
