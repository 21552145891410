import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getItem<T>(key: string, storage: Storage = localStorage): T {
    return JSON.parse(storage.getItem(key));
  }

  setItem<T>(key: string, data: T, storage: Storage = localStorage) {
    return storage.setItem(key, JSON.stringify(data));
  }
}
