import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe transforms number over thousands in to "k".
 *
 * @example
 * 2250 will be transformed in to 2k
 */
@Pipe({
  name: 'countFormat'
})
export class CountFormatPipe implements PipeTransform {

  transform(input: number, args?: any): any {

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return input;
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, exp)).toFixed(args) + 'k';
  }

}
