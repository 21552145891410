import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MatDialogConfig,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { AddressBookComponent } from '../jobs/components/address-book/address-book.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { GridLayoutComponent } from './components/grid-layout/grid-layout.component';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { LoadingIconCellComponent } from './components/loading-icon/loading-icon.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MiniDetailsComponent } from './components/mini-details/mini-details.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ReasonDialogComponent } from './components/reason-dialog/reason-dialog.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SaveChangesDialogComponent } from './components/save-changes-dialog/save-changes-dialog.component';
import { SmallToolbarComponent } from './components/small-toolbar/small-toolbar.component';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { GreaterThanZeroDirective } from './directives/greater-than-zero.directive';
import { OneDecimalDirective } from './directives/one-decimal.directive';
import { PositiveNumberDirective } from './directives/positive-number.directive';
import { CountFormatPipe } from './pipes/count-format.pipe';
import { NoteTypePipe } from './pipes/note-type.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SecureStringPipe } from './pipes/secure-string.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { StatusTypePipe } from './pipes/status-type.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { TbaPipe } from './pipes/tba.pipe';
import { JobCardComponent } from './components/job-card/job-card.component';
import { UserValidationDirective } from './directives/user-validation.directive';
import { GalleryModule, GALLERY_CONFIG } from  'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

/** In shared module we export everything what we import */
const IMPORTS = [
  NgxImgZoomModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  MatDividerModule,
  MatDialogModule,
  MatButtonModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatListModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  FontAwesomeModule,
  MatAutocompleteModule,
  // PerfectScrollbarModule,
  MatIconModule,
  MatProgressBarModule,
  TranslateModule,
  GalleryModule,
  LightboxModule,
  MatMenuModule,
  MatRadioModule
];

const DECLARATIONS = [
  ErrorDialogComponent,
  ConfirmationDialogComponent,
  RegistrationComponent,
  SecureStringPipe,
  NoResultsComponent,
  SmallToolbarComponent,
  GridLayoutComponent,
  ImagesListComponent,
  StatusPipe,
  ReasonDialogComponent,
  TbaPipe,
  MiniDetailsComponent,
  NoteTypePipe,
  StatusTypePipe,
  AddressBookComponent,
  AddressFormComponent,
  GreaterThanZeroDirective,
  SafePipe,
  LoadingIconCellComponent,
  PositiveNumberDirective,
  CurrencyMaskDirective,
  CountFormatPipe,
  SaveChangesDialogComponent,
  OneDecimalDirective,
  LoadingSpinnerComponent,
  SlugifyPipe,
  JobCardComponent,
  UserValidationDirective,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  imports: [
    ...IMPORTS,
  ],
  exports: [
    ...IMPORTS,
    ...DECLARATIONS,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: 800,
        hasBackdrop: true,
        autoFocus: true,
        restoreFocus: true,
      } as MatDialogConfig
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'cover'
      }
    }
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
