import { createAction, props } from '@ngrx/store';
import { User } from '../../users/models/User.model';
import { IJobStats } from '../interfaces/job-stats.interface';

export const sideNavToggle = createAction('[App] Toggle side navigation menu');
export const sideNavMobile = createAction('[App] Change side navigation menu to mobile', props<{displayMobileMenu: boolean}>());

export const setBackUrl = createAction('[App] Set back button url', props<{ backUrl: string }>());
export const setAddressBookIsBusy = createAction('[App/AddressBook] Set Is Busy', props<{ isBusy: boolean }>());
export const setAddressBookIsEditable = createAction('[App/AddressBook] Set Is Editable', props<{ isEditable: boolean }>());

/** Unhandled action */
export const authRequired = createAction('[App/Auth] Auth Required');
/** Unhandled action */
export const setAuthToken = createAction('[App/Auth] AuthenticationToken', props<{ token: string }>());
export const setUserProfile = createAction('[App/Auth] Save user profile to store', props<{ userProfile: Keycloak.KeycloakProfile }>());

export const loadJobStats = createAction('[App/JobStats] Load Job Stats');
export const loadJobStatsSuccess = createAction('[App/JobStats] Load Job Stats Success', props<{ jobStats: IJobStats }>());
export const loadJobStatsError = createAction('[App/JobStats] Load Job Stats Error', props<{ error: Error }>());

export const detectDevice = createAction('[App] Will trigger device detection event');

export const setGroupUser = createAction('[App] Will set if user is a group user');

export const setUsersList = createAction('[App] Will set users list', props<{ usersList: Array<User> }>());
