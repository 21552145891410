import { Pipe, PipeTransform } from '@angular/core';
import { JobStateType } from '@sirius/common';

/**
 * Transform status DTO in to friendly space separated text.
 *
 * @export
 * @class StatusPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  transform(value: JobStateType): any {
    try {
    return value.split('_').join(' ').trim();
    } catch {
      console.warn(`Cannot split transform ${value} in to user friendly status.`);
      return value;
    }
  }
}
