import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  @Input() notifications: Notification[];

  constructor() { }

  ngOnInit() {
  }

}
