import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isArray } from 'lodash';

export interface ReasonDialogData {
  title: string;
  reasons: string[] | { [key: string]: string };
  description: string;
  okButtonText: string;
  cancelButtonText?: string;
}

export interface ReasonFromValue<T = string> {
  reason: T;
  details: string;
}

@Component({
  selector: 'app-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent implements OnInit {
  reasonsForm: UntypedFormGroup;
  reasons: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ReasonDialogData,
    public readonly dialogRef: MatDialogRef<ReasonDialogComponent>
  ) { }

  ngOnInit() {
    this._initReasons();
    this._initReasonsForm();
  }

  private _initReasonsForm() {
    this.reasonsForm = new UntypedFormGroup({
      reason: new UntypedFormControl('', [Validators.required]),
      details: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]),
    });
  }

  private _initReasons() {
    if (!this.data.reasons) {
      return;
    }

    if (isArray(this.data.reasons)) {
      this.reasons = this.data.reasons as string[];
    } else {
      this.reasons = Object.values(this.data.reasons);
    }
  }
}
