import { createSelector } from '@ngrx/store';
import { AppState } from '../interfaces/state.interface';
import { RootState } from './core.reducers';

export const selectRoot = (state: AppState) => state.root;
export const selectSideNavOpen = createSelector(selectRoot, (rootState: RootState) => rootState.sideNavOpen);
export const selectBackUrl = createSelector(selectRoot, (rootState: RootState) => rootState.backUrl);
export const selectRootSideNavItems = createSelector(selectRoot, (rootState: RootState) => rootState.sideNavItemList);
export const selectJobsTotals = createSelector(selectRoot, (rootState: RootState) => rootState.jobStats);
export const selectAddressBookSetup = createSelector(selectRoot, (rootState: RootState) => rootState.addressBookSetup);
export const selectAddressBookIsBusy = createSelector(selectAddressBookSetup, state => state.isBusy);

export const selectUserProfile = createSelector(selectRoot, (rootState: RootState) => rootState.userProfile);

export const selectIsMobile = createSelector(selectRoot, (rootState: RootState) => rootState.isMobile);

export const selectIsGroupUser = createSelector(selectRoot, (rootState: RootState) => rootState.isGroupUser);

export const selectUsersList =  createSelector(selectRoot, (rootState: RootState) => rootState.usersList);
