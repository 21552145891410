/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ActionReducer, createReducer, MetaReducer, on } from '@ngrx/store';
import { User } from 'app/users/models/User.model';
import { cloneDeep } from 'lodash';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MOBILE_MAX_WIDTH } from '../core.constants';
import { IJobStats } from '../interfaces/job-stats.interface';
import { NavigationItem } from '../interfaces/navigation-item.interface';
import { AppState } from '../interfaces/state.interface';
// eslint-disable-next-line max-len
import {
  detectDevice, loadJobStats, loadJobStatsError, loadJobStatsSuccess, setAddressBookIsBusy, setBackUrl, setGroupUser,
  setUserProfile, sideNavMobile, sideNavToggle, setUsersList
} from './core.actions';

export interface RootState {
  sideNavOpen: boolean;
  sideNavMobile: boolean;
  sideNavItemList: NavigationItem[];
  backUrl: string;
  addressBookSetup: {
    isBusy: boolean;
  };
  token: string;
  jobStats: IJobStats;
  userProfile: Keycloak.KeycloakProfile;
  isMobile: boolean;
  usersList: Array<User>;
  isGroupUser: boolean;
}

const initialState: RootState = {
  sideNavOpen: window.innerWidth <= MOBILE_MAX_WIDTH ? false : true,
  sideNavMobile: window.innerWidth <= MOBILE_MAX_WIDTH ? true : false,
  isMobile: window.innerWidth <= MOBILE_MAX_WIDTH ? true : false,
  backUrl: null,
  usersList: [],
  sideNavItemList: [
    {
      label: 'main-side-nav__button-text.jobs',
      icon: 'clipboard-list',
      tooltip: 'main-side-nav-tooltips.jobs',
      url: '/jobs/notification',
      count: 0,
    },
    {
      label: 'main-side-nav__button-text.offers',
      icon: 'hand-holding',
      tooltip: 'main-side-nav-tooltips.offers',
      url: '/offers',
      count: 0,
    },
    {
      label: 'main-side-nav__button-text.imports',
      roles: ['Importer'],
      icon: 'upload',
      tooltip: 'main-side-nav-tooltips.imports',
      url: '/imports',
      count: 0
    },
  ],
  addressBookSetup: {
    isBusy: false,
  },
  userProfile: {},
  token: null,
  jobStats: {
    isBusy: false,
    totalCount: 0,
    countPerStatus: {
      REJECTED: 0,
      OFFERED: 0,
      NOTIFICATION: 0,
      APPOINTMENT: 0,
      ESTIMATED: 0,
      AUTHORITY_REQUEST: 0,
      AUTHORISED: 0,
      WRITE_OFF: 0,
      SELF_AUTHORISED: 0,
      ON_HOLD: 0,
      SUSPENDED: 0,
      IN_PROGRESS: 0,
      COMPLETED: 0,
      INVOICED: 0,
      CANCELLED: 0,
      CLOSED: 0,
    },
  },
  isGroupUser: false,
};

const _appReducer = createReducer(initialState,
  on(sideNavToggle, (state: RootState): RootState => ({ ...state, sideNavOpen: !state.sideNavOpen })),
  on(sideNavMobile, (state: RootState, { displayMobileMenu }): RootState => ({ ...state, sideNavMobile: displayMobileMenu })),
  on(setBackUrl, (state: RootState, { backUrl }): RootState => ({ ...state, backUrl })),
  on(setAddressBookIsBusy, (state: RootState, { isBusy }): RootState => (
    { ...state, addressBookSetup: { ...state.addressBookSetup, isBusy } }
  )),
  on(loadJobStats, (state: RootState): RootState => ({ ...state, jobStats: { ...state.jobStats, isBusy: true } })),
  on(loadJobStatsSuccess, (state: RootState, { jobStats }) => {
    jobStats = { ...jobStats, isBusy: false };
    const sideNavItemList = cloneDeep(state.sideNavItemList).map((item => {
      switch (item.url) {
        case '/offers':
          item.count = jobStats.countPerStatus.OFFERED;
          break;
      }
      return item;
    }));
    return { ...state, jobStats, sideNavItemList };
  }),
  on(loadJobStatsError, (state: RootState): RootState => ({ ...state, jobStats: { ...state.jobStats, isBusy: false } })),
  on(setUserProfile, (state: RootState, { userProfile }): RootState => ({ ...state, userProfile })),
  on(detectDevice, (state: RootState): RootState => ({ ...state, isMobile: window.innerWidth <= MOBILE_MAX_WIDTH ? true : false })),
  on(setGroupUser, (state: RootState): RootState => ({ ...state, isGroupUser: !state.isGroupUser })),
  on(setUsersList, (state: RootState, { usersList }): RootState => ({ ...state, usersList }))
);

export function appReducer(state, action) {
  return _appReducer(state, action);
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => reducer(state, action);
  ;
}

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({ keys: [{ jobs: ['jobsGridSetup'] }] })(reducer);
}

export const reducers = { root: appReducer };
export const metaReducers: MetaReducer<any>[] = [debug, localStorageSyncReducer];
