import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { Observable } from 'rxjs';
import { AppState } from '../../interfaces/state.interface';
import { sideNavToggle } from '../../store/core.actions';
import { selectIsMobile, selectSideNavOpen } from '../../store/core.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /** Logo url, no reason use store for this as its readonly */
  readonly logoUrl = 'https://autoflowfrontend.blob.core.windows.net/assets/autoflow-log.svg';
  public siteName: string;
  public isMobile$: Observable<boolean>;
  public sideNavOpen$: Observable<boolean>;

  constructor(private readonly store: Store, protected keycloakAngular: KeycloakService, private location: Location) { }

  ngOnInit() {
    this.siteName = this.getSiteName();
    this.sideNavOpen$ = this.store.select(selectSideNavOpen);
    this.isMobile$ = this.store.select(selectIsMobile);
  }

  getSiteName() {
    const userDetails = this.keycloakAngular.getKeycloakInstance().idTokenParsed as KeycloakTokenParsed & { siteName: string };
    if (userDetails.siteName) {
      return userDetails.siteName;
    } else {
      console.log('Failed to load site name.');
      return '';
    }
  }

  /**
   * This method toggles main menu
   *
   * @memberof HeaderComponent
   */
  toggleMainSideNav() {
    this.store.dispatch(sideNavToggle());
  }

  navigateBack() {
    this.location.back();
  }

}
