import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { setBackUrl } from '../../../core/store/core.actions';
import { Address } from '../../../core/models/address.model';
import { updateMainContact, setAddressPageIsEditable } from '../../store/job.actions';
import { selectAddressBookSetup } from '../../../core/store/core.selectors';
import { tap } from 'rxjs';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit, OnDestroy {
  @Input() public addresses: Address[];
  @Input() public readonly: boolean;
  isBusy = false;

  constructor(
    private readonly store: Store,
  ) { }

  ngOnInit() {
    this.store.select(selectAddressBookSetup).pipe(
      tap(
          ({ isBusy }) => { this.isBusy = isBusy;}
        )
      )
    .subscribe(
      {
        error: (error) => console.log(error)
      }
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(setBackUrl({ backUrl: '/jobs' }));
  }

  /**
   * Toggles main contact field for provided address by id.
   *
   * @param address
   * @memberof AddressBookComponent
   */
  toggleMainContact({ _id, isMainContact }: Address): void {
    if (isMainContact) {
      return;
    }
    if (this.readonly) {
      console.log('Impossible to update contact when job is in offer state.', AddressBookComponent.name);
      return;
    }
    this.store.dispatch(updateMainContact({ addressId: _id, isMainContact: !isMainContact }));
  }

  setAddressToEdit(isEditable: boolean) {
    this.store.dispatch(setAddressPageIsEditable({ isEditable }));
  }
}
