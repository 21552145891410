import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import  localeFR  from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { AppRoutingModule } from '../app-routing.module';
import { BannerModule } from '../banner/banner.module';
// import { FeatureFlagsModule } from '../feature-flags/feature-flags.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { RecentModule } from '../recent/recent.module';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { BadConnectionBannerComponent } from './components/bad-connection-banner/bad-connection-banner.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MainSideNavComponent } from './components/main-side-nav/main-side-nav.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { SessionExpiryDialogComponent } from './components/session-expiry-dialog/session-expiry-dialog.component';
import { UnderMaintenancePageComponent } from './components/under-maintenance-page/under-maintenance-page.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ServerErrorRetryInterceptor } from './interceptors/server-error-retry-interceptor';
import { TimeoutRetryInterceptor } from './interceptors/timeout-retry-interceptor';
import { keycloakInitializer } from './providers/keycloack-initializer';
import { CoreService } from './services/core.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { StorageService } from './services/storage.service';
import { CoreEffects } from './store/core.effects';
import { metaReducers, reducers } from './store/core.reducers';
import { GlobalSearchModule } from '../global-search/global-search.module';
import { WindowSizeService } from './services/window-size.service';

import { DEFAULT_CURRENCY_DISPLAY } from './core.constants';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/ar-AE';
import '@angular/common/locales/global/ja';
import { FormsModule } from '@angular/forms';

// Add French language local data
registerLocaleData(localeFR);

const CORE_DECLARATIONS = [
  LayoutComponent,
  HeaderComponent,
  UserMenuComponent,
  MainSideNavComponent,
];

const CORE_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  KeycloakAngularModule,
  AppRoutingModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatButtonModule,
  MatMenuModule,
  MatDividerModule,
  MatTooltipModule,
  MatListModule,
  MatDialogModule,
  MatSlideToggleModule,
  FontAwesomeModule,
  MatBadgeModule,
  MatIconModule,
  NotificationsModule,
  BannerModule,
  MatExpansionModule,
  RecentModule,
  // FeatureFlagsModule,
  GlobalSearchModule,
  FormsModule
];

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    ...CORE_MODULES,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([CoreEffects]),
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SocketIoModule.forRoot({
      url: '', options: {
        path: '/sockets/jobs',
      }
    }),
  ],
  exports: [
    ...CORE_MODULES,
    ...CORE_DECLARATIONS,
    StoreModule,
    EffectsModule,
  ],
  declarations: [
    ...CORE_DECLARATIONS,
    NotFoundPageComponent,
    SessionExpiryDialogComponent,
    AboutDialogComponent,
    BadConnectionBannerComponent,
    UnderMaintenancePageComponent,
  ],
  providers: [
    StorageService,
    CoreService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorRetryInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutRetryInterceptor, multi: true
    },
    {
      provide: LOCALE_ID, useValue: 'en-UK',
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP' },
    { provide: DEFAULT_CURRENCY_DISPLAY, useValue: 'symbol' },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService]
    },
    WindowSizeService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, library: FaIconLibrary) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
    library.addIconPacks(fas, far);
  }
}
